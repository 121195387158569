import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useRef, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import CustomEditor from "components/CustomEditor";
import "./styles.scss";
import defaultImage from "assets/imgs/default/privacy_default.jpg";

export function AdminPrivacyPolicy() {
    const fileInputRef = useRef(null);
    const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
    const [privacyPolicy, setPrivacyPolicy] = useState([]);
    const [newImageURL, setNewImageURL] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [formData, setFormData] = useState({
        description: "",
        id: null,
        image: null,  
    });
    const [code, setCode] = useState(``);

    const onUploadAvatar = () => {
        if (!newImage) {
            showNoti("info", "Please upload an image");
            return;
        }

        setFormData({
            ...formData,
            image: newImage, // Set the new image in formData
        })
        setNewImage(null); // Reset newImage after setting it to formData
    };
    const onImageUpload = () => {
        fileInputRef.current.click();
    };


    const onRemoveAvatar = () => {
        if (newImage || newImageURL) {
            setNewImageURL(null);
            setNewImage(null);
        } else {
            // Remove the image key from the formData object
            setFormData((prevData) => ({
                ...prevData,
                image: null, // Set image to null
            }));
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setNewImageURL(reader.result);
            };

            setNewImage(file);
            reader.readAsDataURL(file);
        }
    };
    const fetchPrivacyPlicy = async () => {
        try {
            setLoading(true);
            checkToken();
            const response = await getApi("/admin/getPrivacyPolicy");
            if (response?.data) {
                const { description, id, image } = response.data;

                setFormData({
                    description: description || "",
                    id: id || "",
                    image: image || ""
                });
            }
            setPrivacyPolicy(response.data);
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPrivacyPlicy();
    }, []);


    const handleEditorChange = (content) => {
        setFormData((prevFormData) => ({
            ...prevFormData, // Spread the existing formData to retain other fields
            description: content || "", // Update only the description field
        }));
        setCode(content || "");
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formToSubmit = new FormData();

        formToSubmit.append("description", formData.description);
        formToSubmit.append("id", formData.id);

        // Append the image if it exists
        if (formData.image === null || formData.image === "") {
            formToSubmit.append("is_image", 0);
        }
        if (formData.image && typeof formData.image !== 'string') {
            formToSubmit.append("image", formData.image);  // Append image file
        }

        let apiUrl = "/admin/updatePrivacyPolicy";

        try {
            setLoading(true);
            checkToken();
            await getApi(apiUrl, "POST", formToSubmit);
            showNoti("success", formData.id ? "Privacy Policy Updated Successfully!" : "Privacy Policy Added Successfully!");
            fetchPrivacyPlicy();
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };
    const shortName = () => {
        return 'C';
    } // Assuming placeholder short name
    return (
        <AdminLayout
            page="dashboard"
            caption="Dashboard"
            message="Welcome back admin"
        >
            <div className="admin-privacy body-container">
                <div className="head">
                    <div className="header-testimonial">
                        <Slide className="flex1">
                            <h1>
                                <span>Privacy Policy</span>
                            </h1>
                        </Slide>
                    </div>

                    <div className="mail-settings settings">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                {/* <div className="avatar-section mt-20">
                                    <label htmlFor="image">
                                        Image:
                                    </label>
                                    <div className="avatar">
                                        <div className="avatar-btns">
                                            <CButton addClass="primary mr-5" active={true} onClick={onImageUpload}>
                                                <input
                                                    type="file"
                                                    accept=".png, .gif, .jpg, .jpeg"
                                                    onInput={handleImageUpload}
                                                    onChange={(e) => e.target.value = null}
                                                    style={{ display: "none" }}
                                                    ref={fileInputRef}
                                                />
                                                {(formData.image || newImageURL) ? "Change Image" : "Add Image"}
                                            </CButton>
                                            {formData.image || newImageURL ? (
                                                <CButton addClass="primary mr-5" active={true} onClick={onRemoveAvatar}>
                                                    Remove
                                                </CButton>
                                            ) : null}

                                            {newImage && newImageURL && (
                                                <CButton addClass="primary" active={true} onClick={onUploadAvatar}>
                                                    Upload
                                                </CButton>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="avatar-box">
                                    {newImageURL ? (
                                        <div className="user-avatar">
                                            <img src={newImageURL} alt="profile" />
                                        </div>
                                    ) : formData.image ? (
                                        <div className="user-avatar">
                                            <img src={formData.image} alt="profile" />
                                        </div>
                                    ) : (
                                        <div className="user-avatar">{shortName()}</div>
                                    )}

                                </div> */}
                                 <div className="avatar-section mt-20">
                                    <label htmlFor="image">Image:</label>

                                    {/* Banner Image Container */}
                                    <div className="terms-image">
                                        {newImageURL ? (
                                            <img src={newImageURL} alt="Banner" />
                                        ) : (
                                            formData.image ? (
                                                <img src={formData.image} alt="Banner" />
                                            ) : (
                                                // <div className="user-avatar">{shortName(formData)}</div>
                                                <img src={defaultImage} alt="Banner" />
                                            )
                                        )}
                                    </div>

                                    {/* Buttons for image upload */}
                                    <div className="avatar-btns">
                                        <CButton addClass="primary mr-5" active={true} onClick={onImageUpload}>
                                            <input
                                                type="file"
                                                accept=".png, .gif, .jpg, .jpeg"
                                                onInput={handleImageUpload}
                                                onChange={(e) => e.target.value = null}
                                                style={{ display: "none" }}
                                                ref={fileInputRef}
                                            />
                                            {(formData.image || newImageURL) ? "Change Image" : "Add Image"}
                                        </CButton>

                                        {formData.image || newImageURL ? (
                                            <CButton addClass="primary mr-5" active={true} onClick={onRemoveAvatar}>
                                                Remove
                                            </CButton>
                                        ) : null}

                                        {newImage && newImageURL && (
                                            <CButton addClass="primary" active={true} onClick={onUploadAvatar}>
                                                Upload
                                            </CButton>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="main-body-container">
                                <label htmlFor="description">Description:</label>
                                <CustomEditor
                                    id="bodyCopy"
                                    name="bodyCopy"
                                    value={formData?.description || ""}
                                    code={code || ""}
                                    handleChangeEditor={handleEditorChange}
                                />
                            </div>
                            <button
                                className="custom-button primary active mt-25"
                                type="submit"
                            >
                            Submit
                            </button>
                        </form>
                    </div>

                </div>
            </div>

        </AdminLayout>
    );
}
