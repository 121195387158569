import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import noDataImage from 'assets/imgs/no_data_found.png';
import defaultImage from 'assets/imgs/default/blog_default.jpg';
import { ReactComponent as ArrowForward } from 'assets/imgs/editor/arrow_forward.svg';
import { ReactComponent as ArrowBack } from 'assets/imgs/editor/arrow_back.svg';
import { getApi } from 'utils/customNetwork';
import moment from 'moment';
import { LayoutContext } from 'components/contexts/LayoutContextContainer';
import { LandingPageLayout } from 'components/layouts/LandingPageLayout';
import './blogDetail.scss';

export function BlogDetail() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const { setLoading } = useContext(LayoutContext);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      setLoading(true);
      try {
        const response = await getApi('/getBlogDetails', 'POST', { slug });
        setBlog(response.data);
        setPrevious(response.previous);
        setNext(response.next);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogDetails();
  }, [slug, setLoading]);

  if (!blog) {
    return (
      <LandingPageLayout>
        <div className="no-blog-data">
          <img src={noDataImage} alt="No data found" />
          <h2>No blog information available.</h2>
        </div>
      </LandingPageLayout>
    );
  }

  return (
    <LandingPageLayout>
      <div className="blog-details">
        <h1 className="blog-title">{blog.title}</h1>
        <div className="blog-meta">
          <span className="written-on">Written on: </span>
          <span className="blog-date">{moment(blog.created_at).format('MMM D, YYYY')}</span>
        </div>

        <img className="blog-details-image" src={blog.image || defaultImage} alt={blog.title} />

        <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.description }} />

        <div className="navigation-buttons">
          <div className="button-container">
            {previous && (
              <Link to={`/blog-detail/${previous.slug}`} className="nav-button prev-button">
                <ArrowBack /> Previous
              </Link>
            )}
            {next && (
              <Link to={`/blog-detail/${next?.slug}`} className="nav-button next-button">
                Next <ArrowForward />
              </Link>
            )}
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
}
