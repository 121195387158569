import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Box,
  styled,
  Checkbox,
  IconButton,
  Button,
  InputAdornment,
  Grid,
} from "@mui/material";
import { CustomTableHead } from "./components/CustomTableHead";
import { debounce } from "lodash";
import { COLORS } from "../../utils/theme";
// import Loader from "../Loader";
import { toCapitalCase } from "../../utils/helpers";
import NoDataFound from "../NoDataFound";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { CommonInput } from "components/CommonInput";
import { CommonMultiSelect } from "components/CommonMultiSelect";

const TableBox = styled(Box)({
  backgroundColor: "#333333",
  border: `1px solid #7371fc`,
  borderRadius: "0.5rem",
  width: "auto !important",
  margin: "1rem 0",
});

const CustomTableCell = styled(TableCell)({
  borderBottom: "1px solid #7371fc !important",
  color: COLORS.white,
});

const CustomTablePagination = styled(TablePagination)(({ theme }) => ({
  color: "#FFFFFF",
  ".MuiTablePagination-actions .MuiSvgIcon-root": {
    color: "#7371fc",
  },

  ".MuiTablePagination-selectIcon": {
    color: "#7371fc",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedArray = array?.map((el, index) => [el, index]);
  stabilizedArray?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });
  let filteredArray = stabilizedArray?.map((el) => el[0]);
  if (query && query.search) {
    const searchTerm = query.search.toLowerCase();
    filteredArray = filteredArray.filter((_user) =>
      query.fields.some((field) => {
        if (field === "name") {
          const fullName = `${_user.first_name || ""} ${_user.last_name || ""}`;
          return fullName.toLowerCase().includes(searchTerm);
        }
        if (field === "username") {
          const fullName = `${_user.user.first_name || ""} ${
            _user.user.last_name || ""
          }`;
          return fullName.toLowerCase().includes(searchTerm);
        } else {
          return _user[field]?.toString()?.toLowerCase().includes(searchTerm);
        }
      })
    );
  }
  return filteredArray;
}

export const CommonTable = forwardRef(
  (
    {
      columns,
      rows,
      handleRowDetail,
      searchableFields,
      loading,
      selectable,
      rowsPadding,
      onDragStart,
      draggable,
      onDrop,
      onDragOver,
      onDragEnd,
      isNotDefaultSorted,
      rowsPerPageOptions,
      defaultRowsPerPage,
      actions,
      apiPagination = false,
      fetchData,
      tablePaggination = false,
      tableSearchEnable = false,
      addHeaderButton = false,
      addHeaderButtonName = "ADD",
      addHeaderButtonOnClick,
      addHeaderButtonDisabled = false,
      AddHeaderButtonStyle = {},
      apiSearch = false,
      defaultSortedColumn = "",
      apiSorting = false,
      apiPageInfo,
      tableSelectBox,
      tableSelectBoxName,
      tableSelectBoxOptions,
    },
    ref
  ) => {
    const tableRef = useRef();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState(isNotDefaultSorted ? "" : "asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState(
      isNotDefaultSorted ? "" : defaultSortedColumn ? defaultSortedColumn : ""
    );

    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 10);

    const [searchTerm, setSearchTerm] = useState("");
    const [updatedRow, setUpdatedRow] = useState(rows || []);

    const [selectedOption, setSelectedOption] = useState(
      tableSelectBoxOptions?.length ? tableSelectBoxOptions[0]?.value : ""
    );

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setPage(0);
      setRowsPerPage(parseInt(event.target.value, 10));
    };

    const fetchDataAPICalling = useCallback(async () => {
      let params = {
        page: page + 1,
        perPage: rowsPerPage,
      };

      if (!apiPagination) {
        delete params["page"];
        delete params["limit"];
      }

      fetchData &&
        fetchData({
          params,
        });
    }, [page, rowsPerPage]);

    const searchFetchData = async (newParams) => {
      let params = {
        ...newParams,
      };
      if (!apiPagination) {
        delete params["page"];
        delete params["limit"];
      }

      if (!params?.searchTerm) delete params["searchTerm"];

      fetchData &&
        (await fetchData({
          params,
        }));
    };

    const debouncedAPICall = useCallback(debounce(searchFetchData, 1000), []);

    const handleInputChange = (event) => {
      const searchText = event.target.value;

      if (!apiSearch && searchText) {
        const filteredUsers = searchText
          ? applySortFilter(rows, getComparator(order, orderBy), {
              fields: searchableFields || [],
              search: searchText,
            })
          : rows;
        setUpdatedRow(filteredUsers);
      } else {
        let params = {
          page: page + 1,
          limit: rowsPerPage,
          searchTerm: searchText,
        };
        apiSearch && debouncedAPICall(params, 1000);
      }

      setSearchTerm(searchText);

      if (searchText === "" && !apiSearch) {
        setUpdatedRow(rows);
      }
    };

    const handleInputClear = (flag) => {
      if (tableSearchEnable) {
        setUpdatedRow(rows);
        setSearchTerm("");
        if (flag && tableSelectBox) {
          setSelectedOption(
            tableSelectBoxOptions?.length ? tableSelectBoxOptions[0]?.value : ""
          );
        }
      }
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows?.map?.((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleSelectedRows = (id) => {
      let selectedClone = [...selected];
      let index = selectedClone.findIndex((item) => item === id);
      if (index > -1) {
        selectedClone.splice(index, 1);
      } else {
        selectedClone = [...selectedClone, id];
      }
      setSelected(selectedClone);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      if (!apiSorting) {
        const column = columns.find((col) => col.fieldName === property);
        if (column && column.customSort) {
          const sortedRows = [...updatedRow].sort((a, b) => {
            return column.customSort(a, b, order);
          });

          setUpdatedRow(sortedRows);
        }
      }
    };

    const filteredUsersData = (Users) => {
      const filterData = Users?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      const SkeletonData = [];
      return loading ? SkeletonData : apiPagination ? Users : filterData;
    };

    const isNotFound = !filteredUsersData(updatedRow)?.length || !rows?.length;

    const pageInfo = () => {
      if (!loading && apiPageInfo) {
        const { current_page, per_page, total } = apiPageInfo;
        let from = current_page * per_page - per_page + 1;
        let to = current_page * per_page;
        if (current_page * per_page > total) to = total;
        return `${from}-${to} of ${total}`;
      } else {
        <div
          className="loader-container"
          style={{
            position: "relative",
            height: "auto",
            background: "transparent",
          }}
        >
          <div
            className="spinner"
            style={{ width: 20, height: 20, borderWidth: 4 }}
          ></div>
        </div>;
      }
    };

    const selectBoxHanlderChange = (e) => {
      const selectedValue = e.target.value;
      setSelectedOption(selectedValue);
      const selectedIndex = tableSelectBoxOptions.findIndex(
        (option) => option.value === selectedValue
      );
      if (selectedIndex === 0) {
        setUpdatedRow(rows);
      } else {
        let filterRows = searchTerm ? updatedRow : rows;
        let update_row = filterRows.filter((row) =>
          Array.isArray(row[tableSelectBoxName])
            ? row[tableSelectBoxName].some((value) => value === selectedValue)
            : row[tableSelectBoxName] === selectedValue
        );
        setUpdatedRow(update_row);
      }
    };

    useImperativeHandle(ref, () => ({
      resetState() {
        setSearchTerm("");
        handleInputClear(true);
      },
      getTableElement() {
        return tableRef.current;
      },
    }));

    useEffect(() => {
      setUpdatedRow(rows);
    }, [rows]);

    useEffect(() => {
      fetchDataAPICalling();
    }, [fetchDataAPICalling]);

    useEffect(() => {
      if (!apiSorting) {
        let field =
          orderBy &&
          columns?.find(
            (col) =>
              col?.fieldName === orderBy &&
              col?.sorting &&
              col.hasOwnProperty("customSort")
          );

        if (!field) {
          let filteredUsers = applySortFilter(
            updatedRow,
            getComparator(order, orderBy),
            {
              fields: searchableFields || [],
              search: !apiSearch && searchTerm,
            }
          );
          setUpdatedRow(filteredUsers);
        }
      }
    }, [order, orderBy, apiSorting]);

    return (
      <Box>
        {(tableSearchEnable || addHeaderButton) && (
          <Grid
            container
            display={"flex"}
            justifyContent={addHeaderButton ? "space-between" : "flex-end"}
            alignItems={"center"}
            spacing={2}
            mt={2}
          >
            {tableSearchEnable && (
              <Grid xs={6} sm={6} md={6} lg={3} item>
                <CommonInput
                  handleChange={handleInputChange}
                  value={searchTerm}
                  name={"search"}
                  label={"Search..."}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton style={{ color: COLORS.white }}>
                        {!searchTerm ? (
                          <SearchIcon />
                        ) : (
                          <ClearIcon onClick={handleInputClear} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            )}
            {tableSelectBox && (
              <Grid xs={6} sm={6} md={6} lg={3} item>
                <CommonMultiSelect
                  name={tableSelectBoxName}
                  value={selectedOption}
                  handleChange={selectBoxHanlderChange}
                  options={tableSelectBoxOptions}
                />
              </Grid>
            )}
            {addHeaderButton && (
              <Grid
                xs={6}
                sm={4}
                md={4}
                lg={2}
                item
                sx={{ textAlignLast: "end" }}
              >
                <Button
                  sx={{
                    ...AddHeaderButtonStyle,
                    color: "white",
                    background:
                      "linear-gradient(90deg, #7371FC 0%, #760CFE 100%)",
                    borderRadius: "0.5rem",
                    minWidth: 200,
                  }}
                  variant="contained"
                  onClick={addHeaderButtonOnClick}
                  disabled={addHeaderButtonDisabled}
                >
                  {toCapitalCase(addHeaderButtonName)}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <TableContainer component={TableBox}>
          <Table>
            <CustomTableHead
              {...{ isNotDefaultSorted }}
              selectable={selectable}
              actions={actions}
              columns={columns}
              order={order}
              orderBy={orderBy}
              rowCount={rows?.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <CustomTableCell
                    align="center"
                    colSpan={columns?.length}
                    sx={{ py: 1 }}
                  >
                    <div
                      className="loader-container"
                      style={{
                        position: "relative",
                        height: 100,
                        background: "transparent",
                      }}
                    >
                      <div
                        className="spinner"
                        style={{ width: 20, height: 20 }}
                      ></div>
                    </div>
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            ) : !isNotFound ? (
              <TableBody>
                {filteredUsersData(updatedRow)?.length &&
                  filteredUsersData(updatedRow)?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={row.id}
                        tabIndex={-1}
                        role="checkbox"
                        onClick={() => handleRowDetail && handleRowDetail(row)}
                        draggable={draggable}
                        onDragStart={(e) => onDragStart(e, row.id)}
                        onDrop={(e) => onDrop(e, row.id)}
                        onDragOver={onDragOver}
                        onDragEnd={onDragEnd}
                      >
                        {selectable && (
                          <CustomTableCell sx={{ px: 1, py: 0 }}>
                            <Checkbox
                              style={{
                                color: selected.includes(row.id)
                                  ? COLORS.primaryBlue
                                  : COLORS.border,
                              }}
                              checked={selected.includes(row.id)}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSelectedRows(row.id);
                              }}
                            />
                          </CustomTableCell>
                        )}

                        {columns?.map((column) => {
                          return (
                            <CustomTableCell
                              align={column.align || "left"}
                              key={column.id}
                              sx={{ px: rowsPadding || 2, py: 2 }}
                              style={{ ...(column.rowStyle || null) }}
                            >
                              {column.render
                                ? column.render({
                                    rowData: row,
                                    rowIndex: index,
                                  })
                                : row[column.fieldName]
                                ? toCapitalCase(`${row[column.fieldName]}`)
                                : "-"}
                            </CustomTableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <CustomTableCell
                    align="center"
                    colSpan={columns?.length}
                    sx={{ py: 1 }}
                  >
                    <NoDataFound searchText={searchTerm} />
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>

          {rows?.length || tablePaggination ? (
            <CustomTablePagination
              rowsPerPageOptions={rowsPerPageOptions || [10, 15, 20, 25]}
              component="div"
              count={apiPagination ? apiPageInfo?.total : updatedRow?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => {
                if (loading) {
                  return (
                    <div
                      className="loader-container"
                      style={{
                        position: "relative",
                        height: "auto",
                        background: "transparent",
                      }}
                    >
                      <div
                        className="spinner"
                        style={{ width: 20, height: 20, borderWidth: 4 }}
                      ></div>
                    </div>
                  );
                } else {
                  if (apiPagination) {
                    return pageInfo();
                  } else return `${from}-${to} of ${count}`;
                }
              }}
            />
          ) : null}
        </TableContainer>
      </Box>
    );
  }
);
