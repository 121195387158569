import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { getApi, setToken } from "utils/customNetwork";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  ADMIN_AUTH_ROUTES,
  AUTH_ROUTES,
  LICENSE,
  NO_AUTH_ROUTES,
} from "utils/constants";
import { ImageModal } from "components/atoms/Modal/ImageModal";
import { VideoModal } from "components/atoms/Modal/VideoModal";
import { BasicMembershipModal } from "components/atoms/Modal/BasicMembershipModal";
import { MembershipModal } from "components/atoms/Modal/MembershipModal";
import { AnimationMembershipModal } from "components/atoms/Modal/AnimationMembershipModal";
import { AgencyMembershipModal } from "components/atoms/Modal/AgencyMembershipModal";
import getUpgradeLicense from "utils/getUpgradeLicense";
import { ContactModal } from "components/atoms/Modal/ContactModal";

const LayoutContext = React.createContext(null);

export { LayoutContext };

function LayoutContextContainer(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [upgradeLicense, setUpgradeLicense] = useState(null);

  const onGetUpgradeLicense = ({ user }) => {
    setUpgradeLicense(getUpgradeLicense(user));
  };

  const [expandSidebar, setExpandSidebar] = useState(true);
  const [mobileSide, setMobileSide] = useState(false);
  const [user, setUser] = useLocalStorage("user", null, onGetUpgradeLicense);
  const [adminUser, setAdminUser] = useLocalStorage("admin", null);
  const [loading, setLoading] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [membershipModal, setMembershipModal] = useState(false);
  const [basicMembershipModal, setBasicMembershipModal] = useState(false);
  const [animationMembershipModal, setAnimationMembershipModal] =
    useState(false);
  const [agencyMembershipModal, setAgencyMembershipModal] = useState(false);
  const [image, setImage] = useState({});
  const [images, setImages] = useState([]);
  const [originalImage, setOriginalImage] = useState({});
  const [video, setVideo] = useState({});
  const [videos, setVideos] = useState([]);
  const [videoModal, setVideoModal] = useState(false);
  const [apiCallMade, setApiCallMade] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 900) {
      setMobileSide(false);
      setExpandSidebar(true);
    } else if (window.innerWidth > 767) {
      setMobileSide(false);
      setExpandSidebar(false);
    } else {
      setMobileSide(true);
    }
  };

  const checkUser = async () => {
    try {
      setToken(user.token);
      const data = await getApi("/checkUser", "POST", {
        user_id: user.user.id,
      });

      const expired = Math.floor(Date.now() / 1000) + 10 * 60; // 10 mins
      setToken(data.token);
      setUser({ user: data.user, token: data.token, expired });
      if (data.user.is_first_login) {
        navigate("/finish-registration");
      }
    } catch (error) {
      navigate("/");
    }
  };

  const checkAdminUser = async () => {
    try {
      setToken(adminUser.token);
      const data = await getApi("/checkAdminUser", "POST", {
        user_id: adminUser.user.id,
      });
      const expired = Math.floor(Date.now() / 1000) + 10 * 60; // 10 mins
      setAdminUser({
        user: data.user,
        token: data.token,
        expired,
        type: "admin",
      });
      // navigate("/admin/dashboard");
    } catch (error) {
      navigate("/admin/login");
    }
  };

  const showImageModal = (imageData, images = [], originalImage = {}) => {
    setImage(imageData);
    setImages(images);
    setOriginalImage(originalImage);
    setImageModal(true);
  };

  const showVideoModal = (videoData, videos = []) => {
    setVideo(videoData);
    setVideos(videos);
    setVideoModal(true);
  };

  const showBasicMembershipModal = () => {
    setBasicMembershipModal(true);
  };

  const showMembershipModal = () => {
    setMembershipModal(true);
  };

  const showAnimationMembershipModal = () => {
    setAnimationMembershipModal(true);
  };

  const showAgencyMembershipModal = () => {
    setAgencyMembershipModal(true);
  };

  const createNotification = (type, message = "", title = "Creativio") => {
    switch (type) {
      case "info":
        NotificationManager.info(message, title, 3000);
        break;
      case "success":
        NotificationManager.success(message, title, 3000);
        break;
      case "warning":
        NotificationManager.warning(message, title, 3000);
        break;
      case "error":
        NotificationManager.error(message, title, 5000);
        break;
      case "click":
        NotificationManager.error(message, title, 5000, () => {
          alert("callback");
        });
        break;
      default:
        NotificationManager.error(message, title, 5000);
    }
  };

  const showNoti = (type, message = "", title = "") => {
    createNotification(type, message, title);
    if (
      type === "error" &&
      (message === "Unauthenticated." || message === "Unauthorized")
    ) {
      setTimeout(() => {
        if (ADMIN_AUTH_ROUTES.includes(pathname) && adminUser?.user?.role && adminUser?.user?.role === "admin") {
          setAdminUser(null);
          navigate("/admin/login");
        } else {
          setUser(null);
          navigate("/login");
        }
      }, 3000);
    } else if (
      type === "error" &&
      message === "You don't have enough credits, Please contact to admin."
    ) {
      setTimeout(() => {
        // show license modal
        setMembershipModal(true);
      }, 3000);
    }
  };

  const onCloseImageModal = () => {
    setImageModal(false);
    setImage(null);
    setOriginalImage(null);
  };

  const onCloseVideoModal = () => {
    setVideoModal(false);
    setVideo(null);
  };

  const onCloseBasicMembershipModal = () => {
    setBasicMembershipModal(false);
  };

  const onCloseMembershipModal = () => {
    setMembershipModal(false);
  };

  const onCloseAnimationMembershipModal = () => {
    setAnimationMembershipModal(false);
  };

  const onCloseAgencyMembershipModal = () => {
    setAgencyMembershipModal(false);
  };

  const checkToken = () => {
    if (ADMIN_AUTH_ROUTES.includes(pathname)) {
      setToken(adminUser.token);
    } else if (user) {
      setToken(user.token);
    }
  };

  const getViewImages = useCallback(
    async (
      trend = localStorage.getItem("view_category"),
      viewOption = localStorage.getItem("keyword"),
      searchString = localStorage.getItem("keyword"),
      curPage = 1
    ) => {
      try {
        setLoading(true);
        const data = await getApi("/getImages", "POST", {
          page_size: 20,
          feed_type: "all",
          user_id: user.user.id,
          view_category: trend,
          keyword: searchString,
          generate_type: viewOption,
          page: curPage,
        });
        // Ensure setImages is defined and is a function
        if (typeof setImages === "function") {
          // Ensure data.images is defined and is an array
          if (data.images && Array.isArray(data.images)) {
            // Update state of images
            setImages(data.images);
          } else {
            console.error("data.images is not an array or is undefined");
          }
        } else {
          console.error("setImages is not a function or is undefined");
        }
      } catch (err) {
        showNoti("error", err);
      }
      setLoading(false);
    },
    []
  );

  const clarityImage = async (image) => {
    try {
      setLoading(true);
      setToken(user.token);

      // First API call
      const data = await getApi("/clarityImage", "POST", {
        image_id: image.id,
      });

      // Second API call
      await getViewImages(
        localStorage.getItem("view_category"),
        localStorage.getItem("generate_type"),
        localStorage.getItem("keyword"),
        1
      );

      // Show image modal after both API calls
      showImageModal(data.data, [data.data], image);
    } catch (error) {
      showNoti("error", error);
    } finally {
      setLoading(false);
    }
  };

  const scaleImage = async (image) => {
    try {
      setLoading(true);
      setToken(user.token);

      // First API call
      const data = await getApi("/upscaleImage", "POST", {
        image_id: image.id,
      });

      // Second API call
      await getViewImages(
        localStorage.getItem("view_category"),
        localStorage.getItem("generate_type"),
        localStorage.getItem("keyword"),
        1
      );

      // Show image modal after both API calls
      showImageModal(data.data, [data.data], image);
    } catch (error) {
      showNoti("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (AUTH_ROUTES.includes(pathname)) {
      if (user) {
        if (pathname === "/finish-registration" && !user.user.is_first_login) {
          navigate("/");
          return;
        }
        if (!!user.user.is_first_login) {
          checkUser();
          return;
        }
        const expired = user.expired;
        const current = Math.floor(Date.now() / 1000);
        if (current - Number(expired) < 0) {
          const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins
          setUser({
            user: user.user,
            expired,
            token: user.token,
          });
        } else {
          checkUser();
        }
      } else {
        navigate("/");
      }
    } else if (ADMIN_AUTH_ROUTES.includes(pathname)) {
      if (adminUser) {
        const expired = adminUser.expired;
        const current = Math.floor(Date.now() / 1000);
        if (current - Number(expired) < 0) {
          const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins
          setAdminUser({
            user: adminUser.user,
            expired,
            token: adminUser.token,
          });
        } else {
          checkAdminUser();
        }
      } else {
        navigate("/admin/login");
      }
    } else {
      if (
        !pathname.startsWith("/reset-password") &&
        !pathname.startsWith("/accept-invitation")&&
        !pathname.startsWith("/blog-detail")&&
        !pathname.startsWith("/news-details")
      ) {
        if (!NO_AUTH_ROUTES.includes(pathname)) {
          if (pathname.startsWith("/admin")) {
            navigate("/admin/login");
          }
          else {
            navigate("/");
          }
        }
      }
    }
    /* eslint-disable-next-line */
  }, [pathname]);

  useEffect(() => {
    if (user && AUTH_ROUTES.includes(pathname)) {
      if (pathname === "/finish-registration" && !user.user.is_first_login) {
        navigate("/");
        return;
      }

      if (!!user.user.is_first_login) {
        checkUser();
        return;
      }

      const oldExpired = user.expired;
      const current = Math.floor(Date.now() / 1000);
      if (current - Number(oldExpired) < 0) {
        checkUser();
      } else {
        setToken(user.token);
      }
    } else if (adminUser && ADMIN_AUTH_ROUTES.includes(pathname)) {
      const oldExpired = adminUser.expired;
      const current = Math.floor(Date.now() / 1000);
      if (current - Number(oldExpired) < 0) {
        checkAdminUser();
      } else {
        setToken(adminUser.token);
      }
    } else {
      if (
        !pathname.startsWith("/reset-password") &&
        !pathname.startsWith("/accept-invitation")
      ) {
        if (!NO_AUTH_ROUTES.includes(pathname)) {
          if (pathname.startsWith("/admin")) {
            navigate("/admin/login");
          }
          else {
            navigate("/");
          }
        }
      }
    }

    window.addEventListener("resize", handleResize); // Add event listener on component mount
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener on component unmount
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const signOut = async () => {
    setLoading(true);
    try {
      const data = await getApi("/logout", "POST", {});
      checkToken();
      showNoti("success", data.message);
      setApiCallMade(true);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const onPaddleCheckoutOpen = (upgradeLicense) => {
    if (user && user.user?.source === 'jvzoo' && (upgradeLicense == LICENSE.STARTER || upgradeLicense == LICENSE.BUSINESS)) {
      window.open('https://creativio.io/jvz-special', '_blank');
      return;
    }

    // if (
    //   upgradeLicense == LICENSE.STARTER ||
    //   upgradeLicense == LICENSE.BUSINESS
    // ) {
    //   window.open("https://creativio.io/jvz-special", "_blank");
    //   return;
    // }

    let productId = null;

    switch (upgradeLicense) {
      case LICENSE.STARTER:
        productId = process.env.REACT_APP_PADDLE_STARTER_PRODUCT_ID;
        break;
      case LICENSE.BUSINESS:
        productId = process.env.REACT_APP_PADDLE_BUSINESS_PRODUCT_ID;
        break;
      case LICENSE.PROFESSIONAL:
        productId = process.env.REACT_APP_PADDLE_PRO_PRODUCT_ID;
        break;
      default:
    }

    if (!productId) return;
    const Paddle = window.Paddle;
    try {
      Paddle.Checkout.open({
        items: [
          {
            priceId: productId,
            quantity: 1,
          },
        ],
        settings: {
          theme: "dark",
        },
        customer: {
          email: user?.user?.email,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onUpgradeLicense = () => {
    if (upgradeLicense.includes(LICENSE.STARTER) || upgradeLicense.includes(LICENSE.BUSINESS)) {
      showBasicMembershipModal();
    } else if (upgradeLicense.includes(LICENSE.PROFESSIONAL)) {
      showMembershipModal();
    } else if (upgradeLicense.includes(LICENSE.ANIMATION)) {
      showAnimationMembershipModal();
    } else if (upgradeLicense.includes(LICENSE.AGENCY)) {
      showAgencyMembershipModal();
    }
    // else {
    //   onPaddleCheckoutOpen();
    // }
  };

  return (
    <LayoutContext.Provider
      value={{
        expandSidebar,
        mobileSide,
        user,
        setUser,
        adminUser,
        setAdminUser,
        loading,
        upgradeLicense,
        showNoti,
        setLoading,
        showImageModal,
        showVideoModal,
        showBasicMembershipModal,
        showMembershipModal,
        showAnimationMembershipModal,
        showAgencyMembershipModal,
        setContactModal,
        checkToken,
        scaleImage,
        clarityImage,
        setToken,
        onUpgradeLicense,
        onPaddleCheckoutOpen,
      }}
    >
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <NotificationContainer />
      {props.children}
      <ImageModal
        show={imageModal}
        image={image}
        images={images}
        originalImage={originalImage}
        onClose={onCloseImageModal}
      />
      <VideoModal
        show={videoModal}
        video={video}
        videos={videos}
        onClose={onCloseVideoModal}
      />
      <BasicMembershipModal
        show={basicMembershipModal}
        user={user ? user : ""}
        onClose={onCloseBasicMembershipModal}
      />
      <MembershipModal
        show={membershipModal}
        user={user ? user : ""}
        onClose={onCloseMembershipModal}
      />
      <AnimationMembershipModal
        show={animationMembershipModal}
        user={user ? user : ""}
        onClose={onCloseAnimationMembershipModal}
      />
      <AgencyMembershipModal
        show={agencyMembershipModal}
        user={user ? user : ""}
        onClose={onCloseAgencyMembershipModal}
      />
      <ContactModal
        show={contactModal}
        onClose={() => setContactModal(false)}
      />
    </LayoutContext.Provider>
  );
}

LayoutContextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContextContainer;
