// Pricing.js
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import { getApi } from "utils/customNetwork";
import throttle from 'lodash/throttle';
import noDataImage from 'assets/imgs/no_data_found.png';
import './styles.scss';

// const pricingPlans = [
//   {
//     licenseType: "Basic",
//     source: "Cloud",
//     price: "$10/month",
//     description: "Perfect for individuals looking to get started."
//   },
//   {
//     licenseType: "Pro",
//     source: "Cloud",
//     price: "$20/month",
//     description: "Ideal for small businesses wanting to enhance productivity."
//   },
//   {
//     licenseType: "Enterprise",
//     source: "On-premise",
//     price: "$50/month",
//     description: "Best for large organizations with advanced needs."
//   },
// ];

export function Pricing() {
    const [currentPage, setCurrentPage] = useState(1);
    const [isLast, setIsLast] = useState(false); // Flag to check if it's the last page
    const { setLoading } = useContext(LayoutContext);

    const [pricingPlans, setpricingPlans] = useState([]);
    const itemsPerPage = 10; // Set how many items you want to show per page
    const pricingListRef = useRef(null); // Reference for the pricing list container

    const fetchPlans = async () => {
        if (isLast) return; // Prevent fetching if it's the last page

        setLoading(true);
        try {
            const data = await getApi("/getPricingList", "POST", {
                page: currentPage,
                page_size: itemsPerPage
            });
            console.log(data);
            if (data.records !== undefined && data.records.length > 0) {
                setpricingPlans(prevItems => [...prevItems, ...data.records]);
                setIsLast(data.last); // If `data.last` is true, no more posts to load
            }
        } catch (error) {
            console.error("Error fetching pricing plans:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setpricingPlans([]);
        setCurrentPage(1);
        setIsLast(false);
    }, []);

    useEffect(() => {
        fetchPlans();
    }, [currentPage]);

    const handleScroll = useCallback(
        throttle((e) => {
            if (isLast) return; // If it's the last page, no need to load more

            const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
            const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

            if (scrollPercentage > 95) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        }, 1000), [isLast]
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll); // Clean up event listener
        };
    }, [handleScroll]);

    return (
        <LandingPageLayout >
            <section className="pricing-section">
                <div className="container">
                    <h2>Pricing Plans</h2>
                    {pricingPlans.length > 0 ? (

                        <div className="pricing-list">
                            {pricingPlans.map((plan, index) => (
                                <div className="pricing-item" key={index}>
                                    <h3 className="license-type">{plan.license_type}</h3>
                                    <div className="pricing-content">
                                        <p className="source">Source: {plan.source}</p>
                                        <h4 className="price">Price: ${plan.price}</h4>
                                        <Link to="/sign-up" className="sign-up-button">BUY NOW</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="no-data">
                            <img src={noDataImage} alt="No Data Found" className="no-data-image" />
                            <p>No data found!</p>
                        </div>
                    )}
                </div>
            </section>
        </LandingPageLayout>
    );
};
