export const AUTH_ROUTES = [
  "/dashboard",
  "/finish-registration",
  "/dashboard",
  // "/newbrand",
  "/brand",
  "/create/text-to-image",
  "/create/photoshoot",
  // "/create/edit",
  "/magictool/ai-background-remover",
  "/magictool/magic-remover",
  "/magictool/text-remover",
  '/magictool/image-enhancer',
  '/magictool/image-hd-clarity',
  "/magictool/image-to-text",
  "/magictool/search-replace-object",
  "/magictool/color-changer",
  "/magictool/voice-to-image",
  "/animation/image-to-animation",
  "/animation/animation-feed",
  "/agency/clients",
  "/agency/add-client",
  "/agency/members",
  "/agency/invite-member",
  "/studio-creator",
  "/setting",
  "/assets",
  "/community",
  "/my-feed",
  "/logout",
  "/video-training",
];

export const NO_AUTH_ROUTES = [
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
  "/accept-invitation",
  "/landing-page",
  "/pricing",
  "/features",
  "/new-sign-up",
  "/faq",
  "/terms-of-service",
  "/blogs",
  "/blog-detail",
  "/news",
  "/news-details",
  "/privacy-policy",
  "/contact-us",
  "/admin",
  "/admin/login",
];

export const ADMIN_AUTH_ROUTES = [
  "/admin/dashboard",
  "/admin/users",
  "/admin/licenses",
  "/admin/logout",
  "/admin/settings",
  "/admin/templates",
  "/admin/testimonials",
  "/admin/blogs",
  "/admin/news",
  "/admin/privacypolicy",
  "/admin/termsofservice",
  "/admin/pricing",
  "/admin/feature",
  "/admin/faqs",
  "/admin/statistics",
  "/admin/webinar",
  "/admin/logs",
];

export const LICENSE = {
  FREE_TRIAL: "FreeTrial",
  STARTER: "Starter",
  BUSINESS: "Business",
  PROFESSIONAL: "Professional",
  ANIMATION: "Animation",
  AGENCY: "Agency",
};
