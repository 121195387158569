import { createTheme } from "@mui/material/styles";
// import componentStyleOverrides from "./compStyleOverride";

export const COLORS = {
  primary: {
    dark: "#014d81",
    light: "#30aed0",
    success: "#28a745",
    danger: "#dc3545",
    gray: "#545b62",
    50: "rgba(48, 174, 208, 0.3)",
    10: "rgba(48, 174, 208, 0.1)",
    20: "rgba(48, 174, 208, 0.2)",
    activeTab: "#1B90C4",
  },

  secondary: {
    light: "#ffe721",
  },

  hover: {
    success: "#218838",
    danger: "#c82333",
    gray: "#5a6268",
  },

  background: "#fafafb",
  white: "#ffffff",
  black: "#212121",
  yellow: "#ffe721",
  gray: "#EBECF0",
  darkGray: "#D2D2D2",
  darkShadesGray: "#5A5A5A",
  darkText: "#12709e",
  vividYellow: "#e7fb06",
  primaryBlue: "#2fadcf",
  error: "#FF0000",
  dividerColor: "#cead9c",
  textLightBlack: "#455560",
  sidebarTitle: "rgba(26,51,83,.6)",
  border: "#e6ebf1",
  cardShadow: "0 1px 4px 0px rgb(0 0 0 / 15%)",
  buttonHover: "#014D81",
  statusGray: "#c4c4c4",
  orange: "#FA983C",
  facebook: "#334E90",
  copyLink: "#9b3b62",
  lightGray: "rgb(235, 236, 246,0.4)",
  gray500: "#717171",
  purple: "#6852fe",
};

// const themeOptions = {
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 900,
//       lg: 1200,
//       xl: 1400,
//       xxl: 1640,
//     },
//   },
//   palette: {
//     primary: {
//       main: COLORS.primary.light,
//     },
//     secondary: {
//       main: COLORS.secondary.light,
//     },
//   },
// };

const palette = {
  mode: "light",
  primary: {
    main: "#7371FC",
    dark: "#563bff",
    extraDark: "#36379B",
    darkest: "#1F0C5A",
  },
  primaryGradient: {
    main: "linear-gradient(91deg, #563BFF 1.01%, #7371FC 48.55%, #D94FD5 110.04%)",
  },
  secondaryGradient: {
    main: "linear-gradient(90deg, #48D1CC 18.26%, #3CB371 80.31%)",
  },
};

export const theme = createTheme({ palette });
