import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import moment from "moment";
import noDataImage from 'assets/imgs/no_data_found.png';
import defaultImage from "assets/imgs/default/news_default.png";
import './newsDetail.scss';

export function NewsDetails() {
    const { id } = useParams();
    const [news, setNews] = useState(null);
    const { setLoading } = useContext(LayoutContext);

    useEffect(() => {
        const fetchNewsDetails = async () => {
            setLoading(true);
            try {
                const response = await getApi("/getNewsDetails", "POST", {
                    news_id: id
                });
                console.log(response)
                setNews(response.data);
            } catch (error) {
                console.error("Error fetching news posts:", error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
        fetchNewsDetails();
    }, []);


    if (!news) {
        return (
            <LandingPageLayout>
                <div className="no-data">
                    <img src={noDataImage} alt="No data found" />
                    <h2>No news data available.</h2>
                </div>
            </LandingPageLayout>
        );
    }

    return (
        <LandingPageLayout>
            <section className="news-details-section">
                <div className="news-details-container">
                    <h1 className="news-title">{news.title}</h1>
                    <img
                        className="newsdetails-image"
                        src={news.image || defaultImage}
                        alt={news.title}
                    />
                    <div className="news-info">
                        <span className="news-date">
                            {moment(news.created_at).format('MMM DD, YYYY')}
                        </span>
                        <p className="news-description" dangerouslySetInnerHTML={{ __html: news.description }} />
                    </div>
                </div>
            </section>
        </LandingPageLayout>
    );
}