import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";
import { ReactComponent as IconEdit } from "assets/imgs/action/icon_edit.svg";
import { getApi } from "utils/customNetwork";
import CustomEditor from "components/CustomEditor";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import "./styles.scss";
import { Box, Tooltip } from "@mui/material";
import { CommonTable } from "components/Table";
import moment from "moment/moment";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import { commonSorting } from "utils/util";

export function AdminTemplates() {
  const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [formData, setFormData] = useState({
    mail_template_name: "",
    mail_subject: "",
    mail_body: "",
    from_mail: "",
    id: null,
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [code, setCode] = useState(``);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      checkToken();
      const response = await getApi("/admin/getEmailTemplate");
      setTemplates(response);
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");

    if (keys.length > 1) {
      setFormData((prevData) => {
        const newData = { ...prevData };
        let currentLevel = newData;
        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            currentLevel[key] = value;
          } else {
            currentLevel[key] = currentLevel[key] || {};
            currentLevel = currentLevel[key];
          }
        });
        return newData;
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleEditorChange = (content) => {
    setFormData({
      ...formData,
      mail_body: content || "",
    });
    setCode(content || "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let apiUrl = "/admin/submit-email-form";
    if (formData.id) {
      apiUrl = "/admin/updateEmailTemplate";
    }
    try {
      setLoading(true);
      checkToken();
      const response = await getApi(apiUrl, "POST", formData);
      toggleFormVisibility();
      if (formData.id) {
        showNoti("success", "Email Template Updated Successfully!");
      } else {
        showNoti("success", "Email Template Created Successfully!");
      }
      fetchTemplates();
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    setFormData({
      mail_template_name: "",
      mail_subject: "",
      mail_body: "",
      from_mail: "",
      id: null,
    });
    setCode("");
  };

  const handleTblEdit = (item) => {
    setFormData({
      mail_template_name: item.mail_template_name,
      mail_subject: item.subject,
      mail_body: item.body,
      from_mail: item.from_mail,
      id: item.id,
    });
    setIsFormVisible(true);
  };

  const confirmDeletion = async (item) => {
    setLoading(true);
    try {
      setLoading(true);
      checkToken();
      await getApi(`/admin/deleteEmailTemplate/${item?.id}`, "DELETE");
      showNoti("success", "Email Template Deleted Successfully!");
      fetchTemplates();
      setShowConfirmation(false);
      setItemToDelete(null);
    } catch (err) {
      showNoti("error", err);
    } finally {
      setLoading(false);
    }
  };

  const handleTblDelete = (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const ActionsContainer = ({ item }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip title="Edit">
          <ICONS.Edit
            onClick={() => handleTblEdit(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <ICONS.Delete
            onClick={() => handleTblDelete(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
    );
  };

  const templatesColumns = [
    {
      id: "mail_template_name",
      fieldName: "mail_template_name",
      label: "Template name",
    },
    {
      id: "subject",
      fieldName: "subject",
      label: "Subject",
      align: "center",
    },
    {
      id: "body",
      fieldName: "body",
      label: "Body",
      render: ({ rowData }) => (
        <Box
          dangerouslySetInnerHTML={{
            __html: rowData?.body || "",
          }}
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        ></Box>
      ),
      align: "center",
      sorting: false,
    },
    {
      id: "from_mail",
      fieldName: "from_mail",
      label: "From email",
      align: "center",
    },
    {
      id: "updatedAt",
      fieldName: "updatedAt",
      label: "Updated On",
      render: ({ rowData }) =>
        moment(rowData?.updated_at).format("MMM, DD YYYY hh:mm A"),
      style: {
        display: "flex",
        justifyContent: "flex-end",
      },
      cellStyle: {
        minWidth: 180,
      },
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          moment(dataA?.updated_at).format("MMM, DD YYYY hh:mm A"),
          moment(dataB?.updated_at).format("MMM, DD YYYY hh:mm A"),
          order
        );
      },
      align: "center",
    },
    {
      id: "id",
      fieldName: "id",
      label: "Actions",
      align: "center",
      render: (row) => {
        return <ActionsContainer item={row.rowData} />;
      },
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      sorting: false,
    },
  ];

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-tempalte body-container">
        <div className="head">
          <div className="header-template">
            <Slide className="flex1">
              <h1>
                <span>Email</span> Templates
              </h1>
            </Slide>

            <div className="btn-group add-temp mt-15">
              <div className="btn-mode">
                {isFormVisible ? (
                  <CButton
                    addClass="gradient"
                    active={true}
                    onClick={toggleFormVisibility}
                    disabled={!isFormVisible}
                  >
                    Go Back
                  </CButton>
                ) : (
                  <></>
                  // <CButton
                  //   addClass="gradient"
                  //   active={true}
                  //   onClick={toggleFormVisibility}
                  //   disabled={isFormVisible}
                  // >
                  //   Create Template
                  // </CButton>
                )}
              </div>
            </div>
          </div>
          {isFormVisible ? (
            <div className="mail-settings settings">
              <h4>General Settings (Send mail)</h4>
              <form onSubmit={handleSubmit}>
                <div className="form-group api-setting mt-20">
                  <label htmlFor="mail_template_name">
                    Mail template name:
                  </label>
                  <div className="input-with-icon ">
                    <input
                      type="text"
                      id="mail_template_name"
                      name="mail_template_name"
                      placeholder="Mail template name"
                      value={formData.mail_template_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group api-setting mt-20">
                  <label htmlFor="mail_subject">Subject:</label>
                  <div className="input-with-icon ">
                    <input
                      type="text"
                      id="mail_subject"
                      name="mail_subject"
                      placeholder="Mail Subject"
                      value={formData.mail_subject}
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="main-body-container">
                  <label htmlFor="mail_body">Mail Body:</label>
                  <CustomEditor
                    id="bodyCopy"
                    name="bodyCopy"
                    value={formData?.mail_body || ""}
                    code={code || ""}
                    required
                    handleChangeEditor={handleEditorChange}
                  />
                </div>
                <div className="form-group api-setting mt-20">
                  <label htmlFor="from_mail">From email:</label>
                  <div className="input-with-icon ">
                    <input
                      type="email"
                      required
                      id="from_mail"
                      name="from_mail"
                      placeholder="From email"
                      value={formData.from_mail}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button
                  className="custom-button primary active mt-25"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          ) : (
            // <div className="custom-table mt-25 mb-60">
            //   <table>
            //     <thead>
            //       <tr>
            //         <th className="tableHeader">Template name</th>
            //         <th className="tableHeader">Subject</th>
            //         <th className="tableHeader">Body</th>
            //         <th className="tableHeader">From email</th>
            //         <th className="tableHeader">Action</th>
            //       </tr>
            //     </thead>
            //     <tbody>
            //       {templates.length === 0 ? (
            //         <tr>
            //           <td colSpan="5" className="tableCell text-center">
            //             No records found
            //           </td>
            //         </tr>
            //       ) : (
            //         templates.data.map((template) => (
            //           <tr key={template.id}>
            //             <td className="tableCell">
            //               {template.mail_template_name}
            //             </td>
            //             <td className="tableCell">{template.subject}</td>
            //             <td
            //               className="tableCell"
            //               dangerouslySetInnerHTML={{
            //                 __html: template.body || "",
            //               }}
            //             ></td>
            //             <td className="tableCell">{template.from_mail}</td>
            //             <td className="tableCell text-center">
            //               <IconEdit
            //                 data-tooltip-id="tip-edit"
            //                 data-tooltip-variant="light"
            //                 data-tooltip-content="Edit"
            //                 onClick={() => handleTblEdit(template)}
            //                 className="mr-10"
            //               />
            //               <IconRemove
            //                 data-tooltip-id="tip-delete"
            //                 data-tooltip-variant="light"
            //                 data-tooltip-content="Delete"
            //                 onClick={() => handleTblDelete(template)}
            //               />
            //             </td>
            //           </tr>
            //         ))
            //       )}
            //     </tbody>
            //   </table>
            // </div>
            <CommonTable
              columns={templatesColumns}
              rows={templates?.data}
              tableSearchEnable={true}
              searchableFields={["mail_template_name", "subject", "from_mail"]}
              addHeaderButton={true}
              addHeaderButtonName={"Create Template"}
              addHeaderButtonOnClick={toggleFormVisibility}
              addHeaderButtonDisabled={isFormVisible}
            />
          )}
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationModal
          isOpen={showConfirmation}
          onClose={closeModal}
          onConfirm={() => confirmDeletion(itemToDelete)}
          itemModule="Template"
          itemName={itemToDelete ? itemToDelete?.mail_template_name : ""}
        />
      )}
    </AdminLayout>
  );
}
