import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_API_URL;

const setToken = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const getApi = async (url, method, payload = null, params = null) => {
  try {
    const { data } = await axios({
      method,
      url: BASE_API_URL + url,
      data: payload ? payload : null,
      params: params ? params : null,
      responseType: "json",
    });

    return data;
  } catch (error) {
    let message = "";
    if (error.response) {
      message = error.response.data.message;
    } else {
      message = error.message;
    }
    throw message;
  }
};

export { getApi, setToken };
