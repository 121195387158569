import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import defaultImage from "assets/imgs/default/blog_default.jpg";
import throttle from 'lodash/throttle';
import noDataImage from 'assets/imgs/no_data_found.png';
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import './styles.scss';

export function Blogs() {
    const { setLoading } = useContext(LayoutContext);
    const [blogPosts, setBlogPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 10;
    const [isLast, setIsLast] = useState(false);

    const loadPosts = async () => {
        setLoading(true);
        try {
            const data = await getApi("/getBlogsList", "POST", {
                page: currentPage,
                page_size: postsPerPage
            });
            console.log(data);
            if (data.records !== undefined && data.records.length > 0) {
                setBlogPosts(prevPosts => [...prevPosts, ...data.records]);
                setIsLast(data.last);
            }
        } catch (error) {
            console.error("Error fetching blog posts:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setBlogPosts([]);
        setCurrentPage(1);
    }, []);

    useEffect(() => {
        loadPosts();
    }, [currentPage]);

    const handleScroll = useCallback(
        throttle((e) => {
            if (isLast) return;

            const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
            const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

            if (scrollPercentage > 95) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        }, 1000), [isLast]
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    return (
        <LandingPageLayout>
            <section className="blog-section">
                <h2>Latest Blogs</h2>
                {blogPosts.length > 0 ? (
                    <div className="blog-list">
                        {blogPosts.map((post) => (
                            <div className="blog-item" key={post.id}>
                                <Link to={`/blog-detail/${post.slug}`} className="detailPage">

                                    <div className="blog-image-container col-6">
                                        <img src={post.image != null && post.image !== '' ? post.image : defaultImage} alt={post.title} className="blog-image" />
                                    </div>
                                    <div className="blog-content col-6">
                                        <h3 className="blog-title">{post.title}</h3>
                                        <p className="blog-description" dangerouslySetInnerHTML={{ __html: post.description }} />
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="no-data">
                        <img src={noDataImage} alt="No Data Found" className="no-data-image" />
                        <p>No data found!</p>
                    </div>
                )}
            </section>
        </LandingPageLayout>
    );
}
