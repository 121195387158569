// ContactUs.js
import React, { useState, useContext } from 'react';
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import './styles.scss';

export function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const {
        showNoti,
        setLoading
    } = useContext(LayoutContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const data = await getApi("/submitContactUs", "POST", formData);
            if (data.success) {
                setLoading(false);
                showNoti("success", "Your message has been sent successfully!");
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                });
            }
        } catch (error) {
            console.log(error)
            setLoading(false);

        }
    };

    return (
        <LandingPageLayout>
            <section className="contact-us-section">
                <div className="contact-details">
                    <h2>Contact Us</h2>
                    <p>If you have any questions or need assistance, feel free to reach out to us!</p>
                    <p><strong>Email:</strong> support@creativioai.com</p>
                    <p><strong>Mobile:</strong> +123 456 7890</p>
                    <p>We are here to help you.</p>
                </div>

                <div className="vertical-line"></div>

                <div className="contact-form">
                    <h2>Get in Touch</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">Subject</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-button">Send</button>
                    </form>
                </div>
            </section>
        </LandingPageLayout>
    );
}
