import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import throttle from 'lodash/throttle';
import noDataImage from 'assets/imgs/no_data_found.png';
import defaultImage from "assets/imgs/default/news_default.png";
import './styles.scss';

export function News() {
    const { setLoading } = useContext(LayoutContext);
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 10; // Number of posts per page
    const [isLast, setIsLast] = useState(false); // Flag to check if it's the last page

    const loadPosts = async () => {
        setLoading(true);
        try {
            const data = await getApi("/getNewsList", "POST", {
                page: currentPage,
                page_size: postsPerPage
            });
            if (data.records !== undefined && data.records.length > 0) {
                setNews(prevPosts => [...prevPosts, ...data.records]);
                setIsLast(data.last); // If `data.last` is true, no more posts to load
            }
        } catch (error) {
            console.error("Error fetching news:", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        setNews([]);
        setCurrentPage(1);
    }, []);

    useEffect(() => {
        loadPosts();
    }, [currentPage]);

    const handleScroll = useCallback(
        throttle((e) => {
            if (isLast) return; // If it's the last page, no need to load more

            const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
            const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

            if (scrollPercentage > 95) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        }, 1000), [isLast]
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll); // Clean up event listener
        };
    }, [handleScroll]);

    return (
        <LandingPageLayout>
            <div className="news-section">
                {news.length > 0 ? (
                    news.map(article => (
                        <Link to={`/news-details/${article.id}`} className="newsdetailPage" key={article.id}>
                            <div className="news-block">
                                <img src={(article.image !== null && article.image !== '') ? article.image : defaultImage} alt={article.title} className="news-image" />
                                <div className="news-content">
                                    <h2 className="news-title">{article.title}</h2>
                                    <p className="news-description" dangerouslySetInnerHTML={{ __html: article.description }} />
                                    <Link to={`/news-details/${article.id}`} className="readmore-button">Read More ...</Link>
                                </div>
                            </div>
                        </Link>
                    ))
                ) : (
                    <div className="no-data">
                        <img src={noDataImage} alt="No Data Found" className="no-data-image" />
                        <p>No data found!</p>
                    </div>
                )}
            </div>
        </LandingPageLayout>
    );
}
