import React from "react";
import { ReactComponent as IconEdit } from "assets/imgs/action/icon_edit.svg";
import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";
import { Tooltip } from "react-tooltip";
import { format } from "date-fns";

export default function ClientTable({
  slice = [],
  range = [],
  page = 0,
  handleTblEdit = () => { },
  handleTblDelete = () => { },
  setPage = () => { },
}) {
  const shortName = (data) => {
    if (data.name !== "") {
      return data.name.substring(0, 2).toUpperCase();
    } else {
      return data.business_email.substring(0, 2).toUpperCase();
    }
  };

  return (
    <div className="custom-table">
      <table>
        <thead>
          <tr>
            <th className="tableHeader">Profile Pic</th>
            <th className="tableHeader">Name</th>
            <th className="tableHeader">Business Name</th>
            <th className="tableHeader">Business Description</th>
            <th className="tableHeader">Business Email</th>
            <th className="tableHeader">Business Niche</th>
            <th className="tableHeader">Created At</th>
            <th className="tableHeader">Actions</th>
          </tr>
        </thead>
        <tbody>
          {slice.length === 0 ? (
            <tr>
              <td colSpan="9" style={{ padding: '15px' }} className="tableCell text-center">
                No Records Available!
              </td>
            </tr>
          ) : (
            slice.map((el) => (
              <tr key={el.id}>
                <td className="tableCell">
                  <div className="user-initial">
                    {el.image ? (
                      <div className="user-avatar">
                        <img src={el.image} alt="profile" />
                      </div>
                    ) : (
                      <div className="user-avatar">{shortName(el)}</div>
                    )}
                  </div>
                </td>
                <td className="tableCell">{el.name}</td>
                <td className="tableCell">{el.business_name}</td>
                <td className="tableCell">{el.business_description}</td>
                <td className="tableCell">{el.business_email}</td>
                <td className="tableCell">{el.business_niche}</td>
                <td className="tableCell">{el.createdAt}</td>
                <td className="tableCell text-center">
                  <IconEdit
                    data-tooltip-id="tip-edit"
                    data-tooltip-variant="light"
                    data-tooltip-content="Edit"
                    onClick={() => handleTblEdit(el)}
                    className="mr-10"
                  />
                  <IconRemove
                    data-tooltip-id="tip-delete"
                    data-tooltip-variant="light"
                    data-tooltip-content="Delete"
                    onClick={() => handleTblDelete(el)}
                  />
                </td>
              </tr>
            )))}
        </tbody>
      </table>
      {range.length > 0 && (
        <div className="pagination mb-60 mt-10">
          <button
            onClick={() => setPage(1)}
            disabled={page === 1 || range.length === 1}
          >
            {"<<"}
          </button>
          <button
            onClick={() => setPage(page - 1)}
            disabled={page === 1 || range.length === 1}
          >
            {"<"}
          </button>
          <span>
            Page{" "}
            <strong>
              {page} of {range.length}
            </strong>{" "}
          </span>
          <button
            onClick={() => setPage(page + 1)}
            disabled={range.length === 1 || page === range.length}
          >
            {">"}
          </button>
          <button
            onClick={() => setPage(range.length)}
            disabled={page === range.length}
          >
            {">>"}
          </button>
        </div>
      )}
      <Tooltip id="tip-edit" />
      <Tooltip id="tip-delete" />
    </div>
  );
}
