import {
    setActiveMode,
    setActiveTrend,
    setCurrentPage,
    setImageSize,
    setImageType,
    setIsLast,
    setKeyword,
    setNewImage,
    setOrgImageSize,
    setResultImage,
    setSelectedImage,
    setStep,
    setTrendImages,
} from "store/slices/textRemover/reducer";
import { convertHeicImage } from "pages/Edit/utils";
import { useDispatch, useSelector } from "react-redux";
import {
    getActiveMode,
    getActiveTrend,
    getCurrentPage,
    getIsLast,
    getKeyword,
    getSelectedImage,
    getTrendImages,
} from "store/slices/textRemover/utils";
import { Slide } from "react-awesome-reveal";
import { useState } from "react";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useContext } from "react";
import { CButton } from "components/atoms/CButton";
import { useEffect } from "react";
import { checkPermission } from "utils/util";
import { useNavigate, useSearchParams } from "react-router-dom";
import UploadView from "pages/MagicRemover/components/UploadView";
import FeedHeader from "pages/MagicRemover/components/FeedHeader";
import FeedSearch from "pages/MagicRemover/components/FeedSearch";
import FeedList from "pages/MagicRemover/components/FeedList";

const PAGE_SIZE = 4;

const UploadStep = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const keyword = useSelector(getKeyword);
    const activeTrend = useSelector(getActiveTrend);
    const activeMode = useSelector(getActiveMode);
    const isLast = useSelector(getIsLast);
    const selectedImage = useSelector(getSelectedImage);
    const [activeViewOpt, setActiveViewOpt] = useState("all"); // all | upscaled
    const trendImages = useSelector(getTrendImages);
    const currentPage = useSelector(getCurrentPage);
    const { showNoti, setLoading, checkToken, user } = useContext(LayoutContext);

    const [viewOptions, setViewOptions] = useState([
        {
            key: 1,
            id: "image-to-image",
            icon: null,
            color: "white",
            checked: false,
            label: "Product Photoshoot",
        },
        {
            key: 2,
            id: "all",
            icon: null,
            color: "white",
            checked: true,
            label: "All Images",
        },
        {
            key: 3,
            id: "image-animation",
            icon: null,
            color: "white",
            checked: false,
            label: "Image Animations",
        },
    ]);

    const adjustSizes = ({ height, width }) => {
        let newWidth = width;
        let newHeight = height;
        const aspectRatio = width / height;

        if (width > 768) {
            newWidth = 768;
            newHeight = 768 / aspectRatio;
        }

        return { newHeight, newWidth };
    };

    const parseReader = (reader) => {
        reader.onload = (event) => {
            dispatch(setSelectedImage(reader.result));
            dispatch(setResultImage(reader.result));
            const imageElement = new Image();
            imageElement.src = event.target.result;
            imageElement.onload = function () {
                dispatch(
                    setOrgImageSize({
                        width: imageElement.width,
                        height: imageElement.height,
                    })
                );

                const { newHeight, newWidth } = adjustSizes({
                    width: imageElement.width,
                    height: imageElement.height,
                });

                dispatch(setImageSize({ width: newWidth, height: newHeight }));
                dispatch(setImageType("upload"));
                dispatch(setStep("edit"));
            };
        };
    };

    const handleOptRatio = (id, index, value) => {
        switch (id) {
            case "view_option":
                const customOptions = [...viewOptions];
                customOptions.forEach((el) => (el.checked = false));
                customOptions[index].checked = value;
                getViewImages(activeTrend, customOptions[index].id, keyword, 1);
                setActiveViewOpt(customOptions[index].id);
                setViewOptions(customOptions);
                break;
            default:
        }
    };

    const onClickImageMode = (tp) => {
        if (tp !== activeTrend) {
            getViewImages(tp, activeViewOpt, keyword, 1);
        }
        dispatch(setActiveTrend(tp));
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) return;
        const reader = new FileReader();

        if (file.type === "" || file.type === "image/heic") {
            const blob = await convertHeicImage(file);
            dispatch(setNewImage(blob));
            parseReader(reader);
            reader.readAsDataURL(blob);
            return;
        }

        dispatch(setNewImage(file));
        parseReader(reader);

        reader.readAsDataURL(file);
    };

    const getViewImages = async (
        trend = "trending",
        viewOption = "all",
        searchString = keyword,
        curPage = 1
    ) => {
        try {
            setLoading(true);
            checkToken();
            const data = await getApi("/getImages", "POST", {
                page_size: 4,
                feed_type: "personal",
                view_category: trend,
                user_id: user.user.id,
                keyword: searchString,
                generate_type: viewOption,
                page: curPage,
            });

            dispatch(setCurrentPage(curPage));
            if (curPage > 1) {
                dispatch(setTrendImages([...trendImages, ...data.images]));
            } else {
                dispatch(setTrendImages(data.images));
            }
            dispatch(setIsLast(data.last));
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    };

    const onSearch = () => {
        getViewImages(activeTrend, activeViewOpt, keyword, 1);
    };

    const handleLike = async (image_id, liked) => {
        try {
            checkToken();
    
            await getApi("/like", "POST", {
                image_id,
                is_like: !liked ? "yes" : "no",
            });
    
            const index = trendImages.findIndex(obj => obj.id === image_id);
            if (index !== -1) {
                const updatedImage = {
                    ...trendImages[index],
                    liked: !liked,
                    like_ct: !liked ? trendImages[index].like_ct + 1 : trendImages[index].like_ct - 1
                };
    
                const updatedTrendImages = [...trendImages];
                updatedTrendImages[index] = updatedImage;
    
                dispatch(setTrendImages(updatedTrendImages));
            }
        } catch (err) {
            showNoti("error", err);
        }
    };
    const onReadMore = (arrow) => {
        let curPage = currentPage;
        if (arrow === "forward") {
            if (trendImages.length >= (currentPage + 1) * PAGE_SIZE) {
                curPage++;
            } else {
                if (isLast) {
                    curPage++;
                    getViewImages(activeTrend, activeViewOpt, keyword, curPage);
                } else {
                    return null;
                }
            }
        } else {
            if (curPage === 1) return;
            curPage--;
        }
        dispatch(setCurrentPage(curPage));
    };

    const selectImage = (image) => {
        dispatch(setSelectedImage(image.image));
        dispatch(setResultImage(image.image));
        const imageSize = {
            width: image.width,
            height: image.height,
        };
        dispatch(setOrgImageSize(imageSize));
        dispatch(setImageSize(imageSize));
    };

    const gotoEditor = () => {
        if (selectedImage) {
            dispatch(setImageType("url"));
            dispatch(setStep("edit"));
        } else {
            showNoti("error", "Please select an image");
        }
    };

    const checkParam = () => {
        const paramValue = searchParams.get("show-image");
        if (paramValue) {
            const decode_x = atob(paramValue);
            const parameters = JSON.parse(decode_x);
            dispatch(setStep("edit"));
            setResultImage(parameters.image);
            const size = {
                width: parameters.width,
                height: parameters.height,
            };
            dispatch(setOrgImageSize(size));
            dispatch(setImageSize(size));
            dispatch(setSelectedImage(parameters.image));
        } else {
            getViewImages();
        }
    };

    useEffect(() => {
        if (!checkPermission(user)) {
            showNoti("error", "Permission denied. Please contact the administrator.");
            setTimeout(() => {
                navigate("/");
            }, 2000);
        } else {
            checkParam();
        }
    }, []);

    return (
        <>
            <UploadView
                content={{
                    mainTitle: <>
                        <span>AI Text</span> Remover
                    </>,
                    title: 'Use a Brush to remove text'
                }}
                onChange={handleImageUpload}
            />
            <div id="choose_imaage">
                <div className="section2">
                    <Slide>
                        <h1>
                            <span>Personal</span> Feed
                        </h1>
                    </Slide>
                    <FeedHeader
                        activeTrend={activeTrend}
                        onChangeMode={onClickImageMode}
                        onChangeType={handleOptRatio}
                        options={viewOptions}
                    />
                    <FeedSearch
                        keyword={keyword}
                        onChange={(v) => dispatch(setKeyword(v))}
                        activeMode={activeMode}
                        onSearch={onSearch}
                        setActiveMode={(mode) => dispatch(setActiveMode(mode))}
                    />
                    {trendImages.length !== 0 ? (
            <FeedList
              onLike={handleLike}
              list={trendImages}
              currentPage={currentPage}
              onReadMore={onReadMore}
              selectedImage={selectedImage}
              onSelect={selectImage}
            />
          ) : (
            <div className="no-records-message">
              <p>No records found.</p>
            </div>
          )}    
          {trendImages.length !== 0 ? (
            <div className="text-center mt-20 mb-20">
                <CButton
                    addClass="gradient active py-22i"
                    onClick={gotoEditor}
                    size="medium"
                >
                    Continue With Selected Image
                </CButton>
            </div>
            ) : ( null )}
                </div>
            </div>
        </>
    );
};

export default UploadStep;
