import moment from "moment";
import { LICENSE } from "./constants";

export const isPossible = (license, permission) => {
  /* Old Logic */
  // const licenses = [
  //   LICENSE.FREE_TRIAL,
  //   LICENSE.STARTER,
  //   LICENSE.BUSINESS,
  //   LICENSE.PROFESSIONAL,
  //   LICENSE.ANIMATION,
  //   LICENSE.AGENCY
  // ];
  // const curIndex = licenses.findIndex((ls) => ls === license);
  // const permissionIndex = licenses.findIndex((ls) => ls === permission);

  // if (permissionIndex <= curIndex) return true;
  // else return false;

  /* Temp Logic */
  // const licenseIncludesPermission = Array.isArray(license) ? license.includes(permission) : license === permission;
  // const permissionExists = Object.values(LICENSE).includes(permission);
  // return licenseIncludesPermission && permissionExists;

  // If no specific permissions are required, return true
  if (!permission || permission.length === 0) {
    return true;
  }

  // Ensure license is always an array
  license = Array.isArray(license) ? license : [license];

  // Check if user has at least one of the required permission sets
  return permission.some((permissionSet) =>
    permissionSet.every((permission) => license.includes(permission))
  );
};

export const getLicense = (user) => {
  /* Old Logic */
  // if (user?.license !== "" && Object.values(LICENSE).includes(user?.license)) {
  //   return user?.license;
  // } else {
  //   return LICENSE.FREE_TRIAL;
  // }

  /* Temp Logic */
  // if (Array.isArray(user?.license) && user?.license.length > 0) {
  //   const validLicenses = user.license.filter(license => license !== "" && Object.values(LICENSE).includes(license));
  //   if (validLicenses.length > 0) {
  //     // Check if FREE_TRIAL license is already included
  //     const hasFreeTrial = validLicenses.includes(LICENSE.FREE_TRIAL);
  //     // If FREE_TRIAL is not included, add it to the valid licenses array
  //     if (!hasFreeTrial) {
  //       validLicenses.push(LICENSE.FREE_TRIAL);
  //     }
  //     return validLicenses;
  //   } else {
  //     // If no valid licenses found, return FREE_TRIAL license
  //     return [LICENSE.FREE_TRIAL];
  //   }
  // }

  if (Array.isArray(user?.license) && user?.license.length > 0) {
    const validLicenses = user.license.filter(
      (license) => license !== "" && Object.values(LICENSE).includes(license)
    );
    if (validLicenses.length > 0) {
      return validLicenses;
    } else {
      // If no valid licenses found, return an empty array
      return [];
    }
  }
  // If user licenses are not an array or are empty, return an empty array
  return [];
};

export const showLicense = (license, simple = false) => {
  if (Array.isArray(license)) {
    if (
      simple &&
      license.includes(LICENSE.STARTER) &&
      license.includes(LICENSE.BUSINESS)
    )
      return "Starter or Business";
    if (simple && license.includes(LICENSE.AGENCY)) return "Agency";
    if (simple && license.includes(LICENSE.ANIMATION)) return "Animation";
    if (simple && license.includes(LICENSE.PROFESSIONAL)) return "Pro";
    if (simple && license.includes(LICENSE.BUSINESS)) return "Business";
    if (simple && license.includes(LICENSE.STARTER)) return "Starter";
    if (license.includes(LICENSE.FREE_TRIAL)) return "Free Trial";
  }

  return license;
};

export const checkPermission = (user) => {
  if (user?.user.status === "inactive") return false;
  return true;
};

export const commonSorting = (valueA, valueB, order) => {
  let comparison = 0;
  const isDate = (value) => moment(value, moment.ISO_8601, true).isValid();

  if (typeof valueA === "string" && typeof valueB === "string") {
    if (valueA < valueB) {
      comparison = -1;
    } else if (valueA > valueB) {
      comparison = 1;
    }
  } else if (typeof valueA === "number" && typeof valueB === "number") {
    comparison = valueA - valueB;
  } else if (isDate(valueA) && isDate(valueB)) {
    const dateA = moment(valueA);
    const dateB = moment(valueB);

    if (dateA.isBefore(dateB)) {
      comparison = -1;
    } else if (dateA.isAfter(dateB)) {
      comparison = 1;
    }
  } else {
    const stringA = String(valueA);
    const stringB = String(valueB);
    if (stringA < stringB) {
      comparison = -1;
    } else if (stringA > stringB) {
      comparison = 1;
    }
  }

  return order === "asc" ? comparison : -comparison;
};

export const lineClamp = (lines) => ({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  WebkitLineClamp: lines,
  textOverflow: "ellipsis",
});

export const getFirstTwoLettersCapitalized = (text) => {
  if (!text) return "";

  // Split the text into words
  const words = text.split(" ");

  // Check if there are at least two words
  if (words.length < 2) return "";

  // Get the first letter of the first two words and capitalize them
  const firstLetter = words[0].charAt(0).toUpperCase();
  const secondLetter = words[1].charAt(0).toUpperCase();

  // Return the two capitalized letters as a string
  return firstLetter + secondLetter;
};
