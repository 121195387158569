import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import "./styles.scss";
import { CDropdown } from "components/atoms/CDropdown";
import { Box, Tooltip } from "@mui/material";
import Select from 'react-select';
import { CommonTable } from "components/Table";
import moment from "moment/moment";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import { commonSorting } from "utils/util";
import { LICENSE_OPTIONS, SOURCE_OPTIONS } from "./constants";

export function AdminPricing() {
    const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [pricing, setPricing] = useState([]);
    const [formData, setFormData] = useState({
        license_type: "",
        source: "",
        id: null,
        price: null,  // New field for the image object

    });
    const [licenseOptions, setLicenseOptions] = useState(LICENSE_OPTIONS);
    const [sourceOptions, setSourceOptions] = useState(SOURCE_OPTIONS);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    // const closeRemovePicConfirmationModal = () => {
    //     setShowRemovePicConfirmation(false);
    //     setItemToDelete(null);
    // };

    useEffect(() => {
        fetchPricing();
    }, []);

    const fetchPricing = async () => {
        try {
            setLoading(true);
            checkToken();
            const response = await getApi("/admin/getPricing");
            setPricing(response);
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split(".");

        if (keys.length > 1) {
            setFormData((prevData) => {
                const newData = { ...prevData };
                let currentLevel = newData;
                keys.forEach((key, index) => {
                    if (index === keys.length - 1) {
                        currentLevel[key] = value;
                    } else {
                        currentLevel[key] = currentLevel[key] || {};
                        currentLevel = currentLevel[key];
                    }
                });
                return newData;
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateSelection = (options, type) => {
        const activeOption = getActiveMenu(options);
        if (activeOption.id === null) {
            showNoti("error", `Please select the ${type}`);
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateSelection(licenseOptions, "License Type") || !validateSelection(sourceOptions, "Source")) {
            return;
        }

        let apiUrl = "/admin/createPricing";
        if (formData.id) {
            apiUrl = "/admin/updatePricing";
        }
        try {
            setLoading(true);
            checkToken();
         
            let param = {
                price: formData.price,
                source: getActiveMenu(sourceOptions).id,
                license_type: getActiveMenu(licenseOptions).id,
                id: formData.id,
            };
            const response = await getApi(apiUrl, "POST", param);
            toggleFormVisibility();
            if (formData.id) {
                showNoti("success", "Pricing Updated Successfully!");
            } else {
                showNoti("success", "Pricing Created Successfully!");
            }
            fetchPricing();
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
        setFormData({
            license_type: "",
            source: "",
            id: null,
            price: null
        });
        const defaultSourceOptions = sourceOptions.map(option => ({
            ...option,
            checked: option.id === null
        }));
        const defaultLicenseOptions = licenseOptions.map(option => ({
            ...option,
            checked: option.id === null
        }));
        setSourceOptions(defaultSourceOptions);
        setLicenseOptions(defaultLicenseOptions);
    };

    const handleTblEdit = (item) => {
        setFormData({
            license_type: item.license_type,
            source: item.source,
            id: item.id,
            price: item.price
        });

        const updatedSourceOptions = sourceOptions.map(option => ({
            ...option,
            checked: option.id === item.source 
        }));

        const updatedLicenseOptions = licenseOptions.map(option => ({
            ...option,
            checked: option.id === item.license_type
        }));
        setSourceOptions(updatedSourceOptions);
        setLicenseOptions(updatedLicenseOptions);

        setIsFormVisible(true);
    };

    const confirmDeletion = async (item) => {
        setLoading(true);
        try {
            setLoading(true);
            checkToken();
            await getApi(`/admin/deletePricing/${item?.id}`, "DELETE");
            showNoti("success", "Pricing Deleted Successfully!");
            fetchPricing();
            setShowConfirmation(false);
            setItemToDelete(null);
        } catch (err) {
            showNoti("error", err);
        } finally {
            setLoading(false);
        }
    };

    const handleTblDelete = (item) => {
        setItemToDelete(item);
        setShowConfirmation(true);
    };

    const closeModal = () => {
        setShowConfirmation(false);
        setItemToDelete(null);
    };
    const getActiveMenu = (arr) => {
        const chk = arr.find((k) => k.checked);
        if (chk) return chk;
        else return arr[0];
    };
    const ActionsContainer = ({ item }) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                }}
            >
                <Tooltip title="Edit">
                    <ICONS.Edit
                        onClick={() => handleTblEdit(item)}
                        sx={{ color: COLORS.white, cursor: "pointer" }}
                    />
                </Tooltip>
                <Tooltip title="Delete">
                    <ICONS.Delete
                        onClick={() => handleTblDelete(item)}
                        sx={{ color: COLORS.white, cursor: "pointer" }}
                    />
                </Tooltip>
            </Box>
        );
    };

    const pricingColumns = [

        {
            id: "license_type",
            fieldName: "license_type",
            label: "License Type",
            align: "center",
            sorting: false,
        },
        {
            id: "source",
            fieldName: "source",
            label: "Source",
            render: ({ rowData }) =>
                rowData.source === "paddle" ? "Paddle" : "JvZoo",
            align: "center",
            customSort: (dataA, dataB, order) => {
                return commonSorting(
                    dataA.source === "paddle" ? "Paddle" : "JvZoo",
                    dataB.source === "paddle" ? "Paddle" : "JvZoo",
                    order
                );
            },
        },
        {
            id: "price",
            fieldName: "price",
            label: "price",
        },
        {
            id: "updatedAt",
            fieldName: "updatedAt",
            label: "Updated On",
            render: ({ rowData }) =>
                moment(rowData?.updated_at).format("MMM, DD YYYY"),
            style: {
                display: "flex",
                justifyContent: "flex-end",
            },
            cellStyle: {
                minWidth: 180,
            },
            customSort: (dataA, dataB, order) => {
                return commonSorting(
                    moment(dataA?.updated_at).format("MMM, DD YYYY"),
                    moment(dataB?.updated_at).format("MMM, DD YYYY"),
                    order
                );
            },
            align: "center",
        },
        {
            id: "id",
            fieldName: "id",
            label: "Actions",
            align: "center",
            render: (row) => {
                return <ActionsContainer item={row.rowData} />;
            },
            cellStyle: {
                width: 180,
                minWidth: 180,
            },
            sorting: false,
        },
    ];

    const handleOptRatio = (id, index, value) => {
        switch (id) {
            case "source_option":
                const customOptions1 = [...sourceOptions];
                customOptions1.forEach((el) => (el.checked = false));
                customOptions1[index].checked = value;
                setSourceOptions(customOptions1);
                break;
            case "license_option":
                const customOptions2 = [...licenseOptions];
                customOptions2.forEach((el) => (el.checked = false));
                customOptions2[index].checked = value;
                setLicenseOptions(customOptions2);
                break;
            default:
                break;
        }
    };

    return (
        <AdminLayout
            page="dashboard"
            caption="Dashboard"
            message="Welcome back admin"
        >
            <div className="admin-pricing body-container">
                <div className="head">
                    <div className="header-testimonial">
                        <Slide className="flex1">
                            <h1>
                                <span>Pricing</span>
                            </h1>
                        </Slide>

                        <div className="btn-group add-temp mt-15">
                            <div className="btn-mode">
                                {isFormVisible ? (
                                    <CButton
                                        addClass="gradient"
                                        active={true}
                                        onClick={toggleFormVisibility}
                                        disabled={!isFormVisible}
                                    >
                                        Go Back
                                    </CButton>
                                ) : (
                                    <></>
                                    // <CButton
                                    //   addClass="gradient"
                                    //   active={true}
                                    //   onClick={toggleFormVisibility}
                                    //   disabled={isFormVisible}
                                    // >
                                    //   Create Template
                                    // </CButton>
                                )}
                            </div>
                        </div>
                    </div>
                    {isFormVisible ? (
                        <div className="mail-settings settings">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group mt-20">
                                        <label htmlFor="license_type">
                                            License Type:
                                        </label>
                                        <div className="input-with-icon">
                                            <CDropdown
                                                id="license_option"
                                                addClass="full bg-gray"
                                                showIcon={false}
                                                action={handleOptRatio}
                                                activeMenu={getActiveMenu(licenseOptions)}
                                                menu={licenseOptions}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mt-20">
                                        <p>Source:</p>
                                        <div className="input-with-icon">

                                            <CDropdown
                                                id="source_option"
                                                addClass="full bg-gray"
                                                showIcon={false}
                                                action={handleOptRatio}
                                                activeMenu={getActiveMenu(sourceOptions)}
                                                menu={sourceOptions}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mt-20">
                                        <p>Price:</p>
                                        <div className="input-with-icon">
                                            <input
                                                type="number"
                                                id="price"
                                                name="price"
                                                placeholder="Price"
                                                value={formData.price||''}
                                                onChange={handleChange}
                                                required
                                                step="0.01"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <button
                                    className="custom-button primary active mt-25"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    ) : (

                        <CommonTable
                            columns={pricingColumns}
                            rows={pricing?.data}
                            tableSearchEnable={true}
                            searchableFields={["license_type", "source", "price"]}
                            addHeaderButton={true}
                            addHeaderButtonName={"Create pricing"}
                            addHeaderButtonOnClick={toggleFormVisibility}
                            addHeaderButtonDisabled={isFormVisible}
                        />
                    )}
                </div>
            </div>

            {showConfirmation && (
                <ConfirmationModal
                    isOpen={showConfirmation}
                    onClose={closeModal}
                    onConfirm={() => confirmDeletion(itemToDelete)}
                    itemModule="pricing"
                    itemName={itemToDelete ? itemToDelete?.price : ""}
                />
            )}
        </AdminLayout>
    );
}
