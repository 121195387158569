// Faq.js
import React, { useState, useEffect } from 'react';
import { getApi } from "utils/customNetwork";
import noDataImage from 'assets/imgs/no_data_found.png';
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import './styles.scss';

export function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);
    const [faqs, setFaqs] = useState([]);

    const fetchFaqs = async () => {
        try {
            const data = await getApi("/getFaqList", "GET");
            if (data.data !== undefined && data.data.length > 0) {
                setFaqs(data.data);
            } else {
                setFaqs([]);
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchFaqs();
    }, []);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <LandingPageLayout>
            <section className="faq-section">
                <h2>Frequently Asked Questions</h2>
                {faqs.length > 0 ? (
                    <div className="faq-list">
                        {faqs.map((faq, index) => (
                            <div className="faq-item" key={index}>
                                <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                    <h3>{faq.question}</h3>
                                    <span>{activeIndex === index ? '-' : '+'}</span>
                                </div>
                                {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="no-data">
                        <img src={noDataImage} alt="No Data Found" className="no-data-image" />
                        <p>No data found!</p>
                    </div>
                )}
            </section>
        </LandingPageLayout>
    );
};
