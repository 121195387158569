import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useRef, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import defaultImage from "assets/imgs/default/news_default.png";
import { getApi } from "utils/customNetwork";
import CustomEditor from "components/CustomEditor";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import "./styles.scss";
import { Avatar, Box, Tooltip } from "@mui/material";
import { CommonTable } from "components/Table";
import moment from "moment/moment";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import { commonSorting } from "utils/util";
export function AdminNews() {
    const fileInputRef = useRef(null);
    const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [news, setNews] = useState([]);
    const [newImageURL, setNewImageURL] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        id: null,
        image: null,  // New field for the image object

    });
    const [error, setError] = useState('');

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [code, setCode] = useState(``);

    const onUploadAvatar = () => {
        if (!newImage) {
            showNoti("info", "Please upload an image");
            return;
        }
        setNewImage(null);
        // setNewImageURL(null);
        // const formData = new FormData();
        setFormData({
            ...formData,
            image: newImage, // Set the new image in formData
        })
        // formData.append("image", newImage);
        // updateUserAvatar(formData); 

    };
    const onImageUpload = () => {
        fileInputRef.current.click();
    };


    const onRemoveAvatar = () => {
        if (newImage || newImageURL) {
            setNewImageURL(null);
            setNewImage(null);
        } else {
            // Remove the image key from the formData object
            setFormData((prevData) => ({
                ...prevData,
                image: null, // Set image to null
            }));

            // If you're updating another state (like blogs) at the same time:
            setNews((prevNews) => ({
                ...prevNews,
                image: null, // Clear image from blogs
            }));
        }
    };


    // const closeRemovePicConfirmationModal = () => {
    //     setShowRemovePicConfirmation(false);
    //     setItemToDelete(null);
    // };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setNewImageURL(reader.result);
            };

            setNewImage(file);
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            setLoading(true);
            checkToken();
            const response = await getApi("/admin/getNews");
            setNews(response);
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split(".");

        if (keys.length > 1) {
            setFormData((prevData) => {
                const newData = { ...prevData };
                let currentLevel = newData;
                keys.forEach((key, index) => {
                    if (index === keys.length - 1) {
                        currentLevel[key] = value;
                    } else {
                        currentLevel[key] = currentLevel[key] || {};
                        currentLevel = currentLevel[key];
                    }
                });
                return newData;
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleEditorChange = (content) => {
        setFormData((prevFormData) => ({
            ...prevFormData, // Spread the existing formData to retain other fields
            description: content || "", // Update only the description field
        }));
        setCode(content || "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formToSubmit = new FormData();
        if (!formData.description) {
            setError('Description is required');
            return;
        }
        // Append other form fields to FormData
        formToSubmit.append("title", formData.title);
        formToSubmit.append("description", formData.description);
        formToSubmit.append("id", formData.id);

        // Append the image if it exists
        if (formData.image === null || formData.image === "") {
            formToSubmit.append("is_image", 0);
        }
        if (formData.image && typeof formData.image !== 'string') {
            formToSubmit.append("image", formData.image);  // Append image file
        }

        let apiUrl = "/admin/createNews";
        if (formData.id) {
            apiUrl = "/admin/updateNews";
        }
        try {
            setLoading(true);
            checkToken();
            const response = await getApi(apiUrl, "POST", formToSubmit);
            toggleFormVisibility();
            if (formData.id) {
                showNoti("success", "News Updated Successfully!");
            } else {
                showNoti("success", "News Created Successfully!");
            }
            fetchNews();
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
        setFormData({
            title: "",
            description: "",
            id: null,
            image: null
        });
        setCode("");
        setNewImageURL("");
    };

    const handleTblEdit = (item) => {
        setFormData({
            title: item.title,
            description: item.description,
            id: item.id,
            image: item.image
        });
        setNewImageURL("");
        setIsFormVisible(true);
    };

    const confirmDeletion = async (item) => {
        setLoading(true);
        try {
            setLoading(true);
            checkToken();
            await getApi(`/admin/deleteNews/${item?.id}`, "DELETE");
            showNoti("success", "News Deleted Successfully!");
            fetchNews();
            setShowConfirmation(false);
            setItemToDelete(null);
        } catch (err) {
            showNoti("error", err);
        } finally {
            setLoading(false);
        }
    };

    const handleTblDelete = (item) => {
        setItemToDelete(item);
        setShowConfirmation(true);
    };

    const closeModal = () => {
        setShowConfirmation(false);
        setItemToDelete(null);
    };

    const ActionsContainer = ({ item }) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                }}
            >
                <Tooltip title="Edit">
                    <ICONS.Edit
                        onClick={() => handleTblEdit(item)}
                        sx={{ color: COLORS.white, cursor: "pointer" }}
                    />
                </Tooltip>
                <Tooltip title="Delete">
                    <ICONS.Delete
                        onClick={() => handleTblDelete(item)}
                        sx={{ color: COLORS.white, cursor: "pointer" }}
                    />
                </Tooltip>
            </Box>
        );
    };

    const newsColumns = [
        {
            id: "image",
            fieldName: "image",
            label: "Image",
            render: ({ rowData }) => (
                (rowData.image) ?
                    <Avatar
                        alt={rowData.title}
                        src={rowData.image}
                        style={{ margin: "auto" }}
                    /> :
                    <Avatar
                        alt={rowData.title}
                        src={defaultImage}
                        style={{ margin: "auto" }}
                    />
                // <p className="avatar-placeholder">N/A</p>
            ),
            align: "center",
            cellStyle: {
                minWidth: 70,
            },
            sorting: false,
        },
        {
            id: "title",
            fieldName: "title",
            label: "Title",
        },
        {
            id: "description",
            fieldName: "description",
            label: "Description",
            render: ({ rowData }) => (
                <Box
                    dangerouslySetInnerHTML={{
                        __html: rowData?.description || "",
                    }}
                    style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                ></Box>
            ),
            align: "center",
            sorting: false,
        },
        {
            id: "updatedAt",
            fieldName: "updatedAt",
            label: "Updated On",
            render: ({ rowData }) =>
                moment(rowData?.updated_at).format("MMM, DD YYYY"),
            style: {
                display: "flex",
                justifyContent: "flex-end",
            },
            cellStyle: {
                minWidth: 180,
            },
            customSort: (dataA, dataB, order) => {
                return commonSorting(
                    moment(dataA?.updated_at).format("MMM, DD YYYY"),
                    moment(dataB?.updated_at).format("MMM, DD YYYY"),
                    order
                );
            },
            align: "center",
        },
        {
            id: "id",
            fieldName: "id",
            label: "Actions",
            align: "center",
            render: (row) => {
                return <ActionsContainer item={row.rowData} />;
            },
            cellStyle: {
                width: 180,
                minWidth: 180,
            },
            sorting: false,
        },
    ];


    return (
        <AdminLayout
            page="dashboard"
            caption="Dashboard"
            message="Welcome back admin"
        >
            <div className="admin-news body-container">
                <div className="head">
                    <div className="header-testimonial">
                        <Slide className="flex1">
                            <h1>
                                <span>News</span>
                            </h1>
                        </Slide>

                        <div className="btn-group add-temp mt-15">
                            <div className="btn-mode">
                                {isFormVisible ? (
                                    <CButton
                                        addClass="gradient"
                                        active={true}
                                        onClick={toggleFormVisibility}
                                        disabled={!isFormVisible}
                                    >
                                        Go Back
                                    </CButton>
                                ) : (
                                    <></>

                                )}
                            </div>
                        </div>
                    </div>
                    {isFormVisible ? (
                        <div className="mail-settings settings">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group api-setting mt-20">
                                        <label htmlFor="title">
                                            Title:
                                        </label>
                                        <div className="input-with-icon">
                                            <input
                                                type="text"
                                                id="title"
                                                name="title"
                                                placeholder="Title"
                                                value={formData.title}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="avatar-section mt-20">
                                        <label htmlFor="image">
                                            Image:
                                        </label>
                                        <div className="avatar">
                                            <div className="avatar-btns">
                                                <CButton addClass="primary mr-5" active={true} onClick={onImageUpload}>
                                                    <input
                                                        type="file"
                                                        accept=".png, .gif, .jpg, .jpeg"
                                                        onInput={handleImageUpload}
                                                        onChange={(e) => e.target.value = null}
                                                        style={{ display: "none" }}
                                                        ref={fileInputRef}
                                                    />
                                                    {(formData.image || newImageURL) ? "Change Image" : "Add Image"}
                                                </CButton>
                                                {formData.image || newImageURL ? (
                                                    <CButton addClass="primary mr-5" active={true} onClick={onRemoveAvatar}>
                                                        Remove
                                                    </CButton>
                                                ) : null}

                                                {newImage && newImageURL && (
                                                    <CButton addClass="primary" active={true} onClick={onUploadAvatar}>
                                                        Upload
                                                    </CButton>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="avatar-box">
                                        {newImageURL ? (
                                            <div className="user-avatar">
                                                <img src={newImageURL} alt="profile" />
                                            </div>
                                        ) : (
                                            formData.image ? (
                                                <div className="user-avatar">
                                                    <img src={formData.image} alt="profile" />
                                                </div>
                                            ) : (
                                                <div className="user-avatar">
                                                    <img src={defaultImage} alt="profile" />
                                                </div>
                                                // <div className="user-avatar">{shortName(formData)}</div>
                                            )
                                        )}
                                    </div>
                                </div>

                                <div className="main-body-container">
                                    <label htmlFor="description">Description:</label>
                                    <CustomEditor
                                        id="bodyCopy"
                                        name="bodyCopy"
                                        value={formData?.description || ""}
                                        code={code || ""}
                                        handleChangeEditor={handleEditorChange}
                                    />
                                    {error && <p style={{ color: 'red' }}>{error}</p>}

                                </div>
                                <button
                                    className="custom-button primary active mt-25"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    ) : (

                        <CommonTable
                            columns={newsColumns}
                            rows={news?.data}
                            tableSearchEnable={true}
                            searchableFields={["title", "description"]}
                            addHeaderButton={true}
                            addHeaderButtonName={"Create News"}
                            addHeaderButtonOnClick={toggleFormVisibility}
                            addHeaderButtonDisabled={isFormVisible}
                        />
                    )}
                </div>
            </div>

            {showConfirmation && (
                <ConfirmationModal
                    isOpen={showConfirmation}
                    onClose={closeModal}
                    onConfirm={() => confirmDeletion(itemToDelete)}
                    itemModule="News"
                    itemName={itemToDelete ? itemToDelete?.title : ""}
                />
            )}
        </AdminLayout>
    );
}
