// SignUp.js
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export function SignUp ()  {
  return (
    <div className="landing-page">
      {/* Header */}
      <header className="landing-header">
        <div className="logo">Creativio AI</div>
        <nav className="navigation">
          <Link to="/features">Features</Link>
          <Link to="/pricing">Pricing</Link>
          <Link to="/blogs">Blogs</Link>
          <Link to="/news">News</Link>
          <Link to="/login" className="login-button">Login</Link>
          <Link to="/sign-up" className="login-button">Sign up</Link>
        </nav>
      </header>
      
      {/* Footer */}
      <footer className="landing-footer">
        <nav className="footer-menu">
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/contact-us">Contact Us</Link>
        </nav>
        <p>&copy; 2024 Creativio AI. All rights reserved.</p>
      </footer>
    </div>
  );
};
