// PrivacyPolicy.js
import { React, useEffect, useState } from 'react';
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import { getApi } from "utils/customNetwork";
import bannerdefaultImage from "assets/imgs/default/privacy_default.jpg"
import noDataImage from 'assets/imgs/no_data_found.png';
import './styles.scss';

export function PrivacyPolicy() {
    const [data, setData] = useState(null);

    const fetchPrvacyPolicy = async () => {
        try {
            const data = await getApi("/fetchPricvacyPolicy", "GET");
            if (data.data !== undefined && data.data != null) {
                setData(data.data);
            } else {
                setData(null); // Ensure faqs is an empty array if no data
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        setData(null)
        fetchPrvacyPolicy();
    }, []);
    return (
        <LandingPageLayout>
            <section className="banner-section">
                <img
                    src={data && data.image ? data.image : bannerdefaultImage}
                    alt="Privacy Policy Banner"
                    className="banner-image"
                />
            </section>

            <section className="policy-content">
                {data ? (
                    <div>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                    </div>
                ) : (
                    <div className="no-data">
                        <img src={noDataImage} alt="No Data Found" className="no-data-image" />
                        <p>No Privacy Policy available at this time.</p>
                    </div>
                )}
            </section>
        </LandingPageLayout>
    );
};
