import { LICENSE } from "utils/constants";

export const LICENSE_OPTIONS = [
  {
    key: 1,
    id: null,
    icon: null,
    color: "white",
    checked: true,
    label: "-- Select License --",
  },
  {
    key: 2,
    id: LICENSE.STARTER,
    icon: null,
    color: "white",
    checked: false,
    label: "Starter",
  },
  {
    key: 3,
    id: LICENSE.BUSINESS,
    icon: null,
    color: "white",
    checked: false,
    label: "Business",
  },
  {
    key: 4,
    id: LICENSE.PROFESSIONAL,
    icon: null,
    color: "white",
    checked: false,
    label: "Professional",
  },
  {
    key: 5,
    id: LICENSE.ANIMATION,
    icon: null,
    color: "white",
    checked: false,
    label: "Animation",
  },
  {
    key: 6,
    id: LICENSE.AGENCY,
    icon: null,
    color: "white",
    checked: false,
    label: "Agency",
  },
];

export const SOURCE_OPTIONS = [
  {
    key: 0,
    id: null,
    icon: null,
    color: "white",
    checked: true,
    label: "-- Select Source --",
  },
  {
    key: 1,
    id: 'paddle',
    icon: null,
    color: "white",
    checked: false,
    label: "Paddle",
  },
  {
    key: 2,
    id: 'jvzoo',
    icon: null,
    color: "white",
    checked: false,
    label: "JVZoo",
  },
];