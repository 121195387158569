import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useRef, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import CustomEditor from "components/CustomEditor";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import "./styles.scss";
import { Avatar, Box, Tooltip } from "@mui/material";
import { CommonTable } from "components/Table";
import moment from "moment/moment";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import { commonSorting } from "utils/util";
import defaultImage from "assets/imgs/default/testimonial_default.png";

export function AdminTestimonials() {
  const fileInputRef = useRef(null);
  const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [newProfileImageURL, setNewProfileImageURL] = useState(null);
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [showRemoveProfilePicConfirmation, setShowRemoveProfilePicConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    client_name: "",
    description: "",
    id: null,
    image: null,
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [code, setCode] = useState(``);

  const onUploadAvatar = () => {
    if (!newProfileImage) {
      showNoti("info", "Please upload an image");
      return;
    }
    setNewProfileImage(null);
    // setNewProfileImageURL(null);
    setFormData({
      ...formData,
      image: newProfileImage, // Set the new image in formData
    })
  };
  const onImageUpload = () => {
    fileInputRef.current.click();
  };

  const onRemoveAvatar = () => {
    if (newProfileImage || newProfileImageURL) {
      setNewProfileImageURL(null);
      setNewProfileImage(null);
      return;
    }
    else {
      setFormData((prevData) => ({
        ...prevData,
        image: null, // Set image to null
      }));

      setTestimonials((prevBlogs) => ({
        ...prevBlogs,
        image: null, // Clear image from blogs
      }));
      // setShowRemoveProfilePicConfirmation(true);
    }
  }

  // const closeRemoveProfilePicConfirmationModal = () => {
  //   setShowRemoveProfilePicConfirmation(false);
  //   setItemToDelete(null);
  // };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setNewProfileImageURL(reader.result);
      };

      setNewProfileImage(file);
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      setLoading(true);
      checkToken();
      const response = await getApi("/admin/getTestimonial");
      setTestimonials(response);
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");

    if (keys.length > 1) {
      setFormData((prevData) => {
        const newData = { ...prevData };
        let currentLevel = newData;
        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            currentLevel[key] = value;
          } else {
            currentLevel[key] = currentLevel[key] || {};
            currentLevel = currentLevel[key];
          }
        });
        return newData;
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleEditorChange = (content) => {
    setFormData({
      ...formData,
      description: content || "",
    });
    setCode(content || "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formToSubmit = new FormData();
    formToSubmit.append("client_name", formData.client_name);
    formToSubmit.append("description", formData.description);
    formToSubmit.append("id", formData.id);
    if (formData.image === null || formData.image === "") {
      formToSubmit.append("is_image", 0);
    }
    // Append the image if it exists
    if (formData.image && typeof formData.image !== 'string') {
      formToSubmit.append("image", formData.image);  // Append image file
    }
    let apiUrl = "/admin/createTestimonial";
    if (formData.id) {
      apiUrl = "/admin/updateTestimonial";
    }
    try {
      setLoading(true);
      checkToken();
      await getApi(apiUrl, "POST", formToSubmit);
      toggleFormVisibility();
      if (formData.id) {
        showNoti("success", "Testimonial Updated Successfully!");
      } else {
        showNoti("success", "Testimonial Created Successfully!");
      }
      fetchTestimonials();
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    setFormData({
      client_name: "",
      description: "",
      id: null,
      image: null
    });
    setCode("");
    setNewProfileImageURL("");
  };

  const handleTblEdit = (item) => {
    setFormData({
      client_name: item.client_name,
      description: item.description,
      id: item.id,
      image: item.image
    });
    setNewProfileImageURL("");
    setIsFormVisible(true);
  };

  const confirmDeletion = async (item) => {
    setLoading(true);
    try {
      setLoading(true);
      checkToken();
      await getApi(`/admin/deleteTestimonial/${item?.id}`, "DELETE");
      showNoti("success", "Testimonial Deleted Successfully!");
      fetchTestimonials();
      setShowConfirmation(false);
      setItemToDelete(null);
    } catch (err) {
      showNoti("error", err);
    } finally {
      setLoading(false);
    }
  };

  const handleTblDelete = (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const ActionsContainer = ({ item }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip title="Edit">
          <ICONS.Edit
            onClick={() => handleTblEdit(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <ICONS.Delete
            onClick={() => handleTblDelete(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
    );
  };

  const testimonialsColumns = [
    {
      id: "image",
      fieldName: "image",
      label: "Image",
      render: ({ rowData }) => (
        (rowData.image) ?
          <Avatar
            alt={rowData.title}
            src={rowData.image}
            style={{ margin: "auto" }}
          /> :
          <Avatar
            alt={rowData.title}
            src={defaultImage}
            style={{ margin: "auto" }}
          />
        // <p className="avatar-placeholder">N/A</p>
      ),
      align: "center",
      cellStyle: {
        minWidth: 70,
      },
      sorting: false,
    },
    {
      id: "client_name",
      fieldName: "client_name",
      label: "Client Name",
    },
    {
      id: "description",
      fieldName: "description",
      label: "Description",
      render: ({ rowData }) => (
        <Box
          dangerouslySetInnerHTML={{
            __html: rowData?.description || "",
          }}
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        ></Box>
      ),
      align: "center",
      sorting: false,
    },
    {
      id: "updatedAt",
      fieldName: "updatedAt",
      label: "Updated On",
      render: ({ rowData }) =>
        moment(rowData?.updated_at).format("MMM, DD YYYY"),
      style: {
        display: "flex",
        justifyContent: "flex-end",
      },
      cellStyle: {
        minWidth: 180,
      },
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          moment(dataA?.updated_at).format("MMM, DD YYYY"),
          moment(dataB?.updated_at).format("MMM, DD YYYY"),
          order
        );
      },
      align: "center",
    },
    {
      id: "id",
      fieldName: "id",
      label: "Actions",
      align: "center",
      render: (row) => {
        return <ActionsContainer item={row.rowData} />;
      },
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      sorting: false,
    },
  ];

  const shortName = () => {
    if (formData.client_name !== "") {
      return formData.client_name.substring(0, 2).toUpperCase();
    } else {
      return 'C';
    }
  };

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-testimonial body-container">
        <div className="head">
          <div className="header-testimonial">
            <Slide className="flex1">
              <h1>
                <span>Testimonials</span>
              </h1>
            </Slide>

            <div className="btn-group add-temp mt-15">
              <div className="btn-mode">
                {isFormVisible ? (
                  <CButton
                    addClass="gradient"
                    active={true}
                    onClick={toggleFormVisibility}
                    disabled={!isFormVisible}
                  >
                    Go Back
                  </CButton>
                ) : (
                  <></>
                  // <CButton
                  //   addClass="gradient"
                  //   active={true}
                  //   onClick={toggleFormVisibility}
                  //   disabled={isFormVisible}
                  // >
                  //   Create Template
                  // </CButton>
                )}
              </div>
            </div>
          </div>
          {isFormVisible ? (
            <div className="mail-settings settings">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group api-setting mt-20">
                    <label htmlFor="title">
                      Client Name:
                    </label>
                    <div className="input-with-icon">
                      <input
                        type="text"
                        id="client_name"
                        name="client_name"
                        placeholder="Client Name"
                        value={formData.client_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="avatar-section mt-20">
                    <label htmlFor="title">
                      Client Image:
                    </label>
                    <div className="avatar">
                      <div className="avatar-btns">
                        <CButton addClass="primary mr-5" active={true} onClick={onImageUpload}>
                          <input
                            type="file"
                            accept=".png, .gif, .jpg, .jpeg"
                            onInput={handleImageUpload}
                            onChange={(e) => e.target.value = null}
                            style={{ display: "none" }}
                            ref={fileInputRef}
                          />
                          {(formData.image || newProfileImageURL) ? "Change Profile Picture" : "Add Profile Picture"}
                        </CButton>
                        {formData.image || newProfileImageURL ? (
                          <CButton addClass="primary mr-5" active={true} onClick={onRemoveAvatar}>
                            Remove
                          </CButton>
                        ) : null}

                        {newProfileImage && newProfileImageURL && (
                          <CButton addClass="primary" active={true} onClick={onUploadAvatar}>
                            Upload
                          </CButton>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="avatar-box">
                    {newProfileImageURL ? (
                      <div className="user-avatar">
                        <img src={newProfileImageURL} alt="profile" />
                      </div>
                    ) : (
                      formData.image ? (
                        <div className="user-avatar">
                          <img src={formData.image} alt="profile" />
                        </div>
                      ) : (
                        <div className="user-avatar">
                          <img src={defaultImage} alt="profile" />
                        </div>
                        // <div className="user-avatar">{shortName(formData)}</div>
                      )
                    )}
                  </div>
                </div>

                <div className="main-body-container">
                  <label htmlFor="description">Description:</label>
                  <CustomEditor
                    id="bodyCopy"
                    name="bodyCopy"
                    value={formData?.description || ""}
                    code={code || ""}
                    handleChangeEditor={handleEditorChange}
                  />
                </div>
                <button
                  className="custom-button primary active mt-25"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          ) : (
            // <div className="custom-table mt-25 mb-60">
            //   <table>
            //     <thead>
            //       <tr>
            //         <th className="tableHeader">Template name</th>
            //         <th className="tableHeader">Subject</th>
            //         <th className="tableHeader">Body</th>
            //         <th className="tableHeader">From email</th>
            //         <th className="tableHeader">Action</th>
            //       </tr>
            //     </thead>
            //     <tbody>
            //       {testimonials.length === 0 ? (
            //         <tr>
            //           <td colSpan="5" className="tableCell text-center">
            //             No records found
            //           </td>
            //         </tr>
            //       ) : (
            //         testimonials.data.map((template) => (
            //           <tr key={template.id}>
            //             <td className="tableCell">
            //               {template.title}
            //             </td>
            //             <td className="tableCell">{template.subject}</td>
            //             <td
            //               className="tableCell"
            //               dangerouslySetInnerHTML={{
            //                 __html: template.body || "",
            //               }}
            //             ></td>
            //             <td className="tableCell">{template.from_mail}</td>
            //             <td className="tableCell text-center">
            //               <IconEdit
            //                 data-tooltip-id="tip-edit"
            //                 data-tooltip-variant="light"
            //                 data-tooltip-content="Edit"
            //                 onClick={() => handleTblEdit(template)}
            //                 className="mr-10"
            //               />
            //               <IconRemove
            //                 data-tooltip-id="tip-delete"
            //                 data-tooltip-variant="light"
            //                 data-tooltip-content="Delete"
            //                 onClick={() => handleTblDelete(template)}
            //               />
            //             </td>
            //           </tr>
            //         ))
            //       )}
            //     </tbody>
            //   </table>
            // </div>
            <CommonTable
              columns={testimonialsColumns}
              rows={testimonials?.data}
              tableSearchEnable={true}
              searchableFields={["client_name", "description"]}
              addHeaderButton={true}
              addHeaderButtonName={"Create Testimonial"}
              addHeaderButtonOnClick={toggleFormVisibility}
              addHeaderButtonDisabled={isFormVisible}
            />
          )}
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationModal
          isOpen={showConfirmation}
          onClose={closeModal}
          onConfirm={() => confirmDeletion(itemToDelete)}
          itemModule="Testimonial"
          itemName={itemToDelete ? itemToDelete?.client_name : ""}
        />
      )}
    </AdminLayout>
  );
}
