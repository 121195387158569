import React, { useEffect, useState, useContext, useCallback } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { LandingPageLayout } from "components/layouts/LandingPageLayout";
import { getApi } from "utils/customNetwork";
import throttle from "lodash/throttle";
import noDataImage from "assets/imgs/no_data_found.png";
import defaultImage from "assets/imgs/default/feaures_default.jpg";
import "./styles.scss";
import { Box, Container, Stack, Typography } from "@mui/material";
import { theme } from "utils/theme";

export function Features() {
  const { setLoading } = useContext(LayoutContext);
  const [features, setFeatures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isLast, setIsLast] = useState(false); // Flag to check if it's the last page

  const loadFeatures = async () => {
    // setLoading(true);
    try {
      const data = await getApi("/getFeatresList", "POST", {
        page: currentPage,
        page_size: itemsPerPage,
      });
      if (data.records !== undefined && data.records.length > 0) {
        setFeatures((prevPosts) => [...prevPosts, ...data.records]);
        setIsLast(data.last); // If `data.last` is true, no more posts to load
      }
    } catch (error) {
      console.error("Error fetching features:", error);
      // setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    setFeatures([]);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    loadFeatures();
  }, [currentPage]);

  const handleScroll = useCallback(
    throttle(() => {
      if (isLast) return; // If it's the last page, no need to load more

      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const scrollPercentage =
        (scrollTop / (documentHeight - windowHeight)) * 100;

      if (scrollPercentage > 95) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, 1000),
    [isLast]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Clean up
  }, [handleScroll]);

  return (
    <LandingPageLayout>
      <Stack
        sx={{ justifyContent: "center", alignItems: "center", py: 8, gap: 8 }}
      >
        <Stack sx={{ gap: 1.5, alignItems: "center" }}>
          <Typography
            variant="h3"
            sx={{
              background: theme.palette.primaryGradient.main,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 600,
              fontSize: 45,
              textAlign: "center",
            }}
          >
            Creativio's Unmatched Features:
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.common.black,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Elevate Your Product Photography Like Never Before
          </Typography>
        </Stack>

        <Container
          maxWidth="xl"
          sx={{
            background:
              "linear-gradient(0deg, rgba(115, 113, 252, 0.23) 4%, rgba(177, 109, 246, 0.10) 95%)",

            borderRadius: 10,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stack sx={{ py: { xs: 4, sm: 8 }, px: { xs: 1.5, sm: 5 } }}>
              {features.length ? (
                <Stack
                  sx={{
                    gap: 8,
                  }}
                >
                  {features.map(
                    ({ id, image, feature_name, description }, index) => {
                      const flexDirection =
                        index % 2 === 0 ? "row" : "row-reverse";
                      return (
                        <Stack
                          key={id}
                          sx={{
                            flexDirection: { xs: "column", sm: flexDirection },
                            gap: { xs: 3, sm: 6 },
                            justifyContent: "space-between",
                            alignItems: "center",
                            p: { xs: 2.5, sm: 5 },
                            bgcolor: theme.palette.common.white,
                            borderRadius: 6,
                            boxShadow:
                              "0px 7px 38px 0px rgba(103, 110, 114, 0.10)",
                          }}
                        >
                          <Stack
                            sx={{
                              width: { xs: "100%", sm: "50%" },
                              flexDirection: {
                                xs: "column",
                                sm: flexDirection,
                              },
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={
                                image !== null && image !== ""
                                  ? image
                                  : defaultImage
                              }
                              sx={{
                                p: 0.8,
                                borderRadius: 5,
                                backgroundImage:
                                  "linear-gradient(103deg, #563bff 0%, #7371fc 43.6%, #d94fd5 100%)",
                                width: "calc(100% - 12.8px)",
                                maxWidth: {
                                  xs: "calc(100% - 12.8px)",
                                  sm: "300px",
                                },
                                height: "auto",
                              }}
                              alt={feature_name}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              justifyContent: "center",
                              gap: 3,
                              width: { xs: "100%", sm: "50%" },
                            }}
                          >
                            <Stack
                              sx={{
                                alignItems: "center",
                                flexDirection: "row",
                                gap: 2,
                              }}
                            >
                              <Stack
                                sx={{
                                  background:
                                    theme.palette.primaryGradient.main,
                                  height: "45px",
                                  width: "45px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  aspectRatio: "1/1",
                                  lineHeight: "unset",
                                }}
                              >
                                <Typography
                                  variant="h4"
                                  sx={{
                                    color: theme.palette.common.white,
                                    fontWeight: 600,
                                    fontSize: 30,
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </Stack>
                              <Typography
                                variant="h4"
                                sx={{ color: "#36379B", fontWeight: 600 }}
                              >
                                {feature_name}
                              </Typography>
                            </Stack>
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}
                              sx={{ p: { fontSize: 18, color: "black" } }}
                            />
                          </Stack>
                        </Stack>
                      );
                    }
                  )}
                </Stack>
              ) : (
                <Stack
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    pt: 5,
                  }}
                >
                  <Box
                    component="img"
                    src={noDataImage}
                    alt="No Data Found"
                    sx={{ maxWidth: 500 }}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        </Container>
      </Stack>
      {/* <section className="features-class">
        <div className="featres-section">
          {features.length > 0 ? (
            features.map((feature, index) => (
              <div
                key={feature.id}
                className="feature-block"
                style={{
                  flexDirection: index % 2 === 0 ? "row" : "row-reverse", // Directly control layout based on index
                }}
              >
                <div className="feature-image">
                  <img
                    src={
                      feature.image !== null && feature.image !== ""
                        ? feature.image
                        : defaultImage
                    }
                    alt={feature.feature_name}
                  />
                </div>
                <div className="feature-text">
                  <h3>
                    {index + 1}. {feature.feature_name}
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: feature.description }}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="no-data">
              <img
                src={noDataImage}
                alt="No Data Found"
                className="no-data-image"
              />
              <p>No data found!</p>
            </div>
          )}
        </div>
      </section> */}
    </LandingPageLayout>
  );
}
