import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Stack,
  useTheme,
  Typography,
  Button,
  Drawer,
  Divider,
  ThemeProvider,
} from "@mui/material";
import { ICONS, IMAGES } from "assets";
import useToggleState from "hooks/useToggleState";
import { theme as mainTheme } from "utils/theme";
import { PAGES } from "utils/urls";

export function LandingPageLayout({ children }) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const { state, toggle } = useToggleState();

  const currentYear = new Date().getFullYear();

  const MENU_ITEMS = [PAGES.FEATURES, PAGES.PRICING, PAGES.BLOGS, PAGES.NEWS];

  const FOOTER_MENU_ITEMS = [
    PAGES.PRIVACY_POLICY,
    PAGES.TERMS_OF_SERVICE,
    PAGES.FAQS,
    PAGES.CONTACT_US,
  ];

  const headerHeight = 75;

  return (
    <ThemeProvider theme={mainTheme}>
      <Stack>
        <AppBar
          sx={{
            width: "100%",
            zIndex: theme.zIndex.drawer + 1,
            bgcolor: "#1F0C5A",
            height: `${headerHeight}px`,
          }}
        >
          <Container maxWidth="xl" sx={{ height: "100%" }}>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Link to={PAGES.HOME.path} style={{ textDecoration: "none" }}>
                <Box component="img" src={IMAGES.LandingLogo} alt="logo" />
              </Link>

              <Button
                variant="outlined"
                size="small"
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  justifyContent: "center",
                  alignItems: "center",
                  p: "5px",
                  minWidth: "auto",
                }}
                onClick={toggle}
              >
                <ICONS.MenuBars
                  sx={{
                    color: theme.palette.common.white,
                    fontSize: 28,
                  }}
                />
              </Button>

              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
              >
                {MENU_ITEMS.map(({ id, name, path }) => {
                  return (
                    <Link key={id} to={path} style={{ textDecoration: "none" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.common.white,
                          position: "relative",
                          "&::before": {
                            content: '""',
                            width: path === pathname ? "100%" : "0%",
                            height: "2px",
                            backgroundColor: "#7371FC",
                            display: "block",
                            transition: "width 0.5s",
                            position: "absolute",
                            left: 0,
                            bottom: -2,
                            borderRadius: "2px",
                          },
                          "&:hover::before": {
                            width: "100%",
                          },
                        }}
                      >
                        {name}
                      </Typography>
                    </Link>
                  );
                })}
                <Link to={PAGES.LOGIN.path} style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      borderRadius: "36.5px",
                      background: mainTheme.palette.primaryGradient.main,
                    }}
                  >
                    Login
                  </Button>
                </Link>
              </Stack>
            </Stack>
          </Container>
        </AppBar>

        <Box
          sx={{
            marginTop: `${headerHeight}px`,
            minHeight: `calc(100vh - ${headerHeight}px)`,
            bgcolor: theme.palette.common.white,
          }}
        >
          {children}
        </Box>

        <Stack
          sx={{
            bgcolor: "#1F0C5A",
            position: "relative",
            pt: "80px",
            "&::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "80px",
              backgroundColor: "white",
              clipPath: "polygon(100% 100%, 100% 0, 0 0);",
              top: -1,
              left: 0,
            },
          }}
        >
          <Container maxWidth="xl">
            <Stack sx={{ py: 5, gap: 3 }}>
              <Stack>
                <Stack
                  sx={{
                    flexDirection: { md: "row" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Link to={PAGES.HOME.path} style={{ textDecoration: "none" }}>
                    <Box component="img" src={IMAGES.LandingLogo} alt="logo" />
                  </Link>
                  <Stack
                    sx={{
                      flexDirection: { sm: "row" },
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {FOOTER_MENU_ITEMS.map(({ id, name, path }) => {
                      return (
                        <Link
                          key={id}
                          to={path}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: theme.palette.common.white,
                              position: "relative",
                              "&::before": {
                                content: '""',
                                width: path === pathname ? "100%" : "0%",
                                height: "2px",
                                backgroundColor: "#7371FC",
                                display: "block",
                                transition: "width 0.5s",
                                position: "absolute",
                                left: 0,
                                bottom: -2,
                                borderRadius: "2px",
                              },
                              "&:hover::before": {
                                width: "100%",
                              },
                            }}
                          >
                            {name}
                          </Typography>
                        </Link>
                      );
                    })}
                  </Stack>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      borderRadius: "36.5px",
                      background: mainTheme.palette.primaryGradient.main,
                    }}
                  >
                    Get Access Now
                  </Button>
                </Stack>
              </Stack>
              <Divider sx={{ bgcolor: "#412A88" }} />
              <Stack sx={{ alignItems: "center" }}>
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.grey[500] }}
                >
                  © {currentYear}, Creativio. All rights reserved
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        {/* Mobile menu */}
        <Drawer anchor="top" open={state}>
          <Stack
            sx={{
              marginTop: `${headerHeight}px`,
              minHeight: `calc(100vh - (${headerHeight}px + 112px))`,
              bgcolor: theme.palette.common.white,
              p: 7,
              gap: 4,
              alignItems: "center",
            }}
          >
            {MENU_ITEMS.map(({ id, name, path }) => {
              return (
                <Link
                  key={id}
                  to={path}
                  style={{ textDecoration: "none" }}
                  onClick={toggle}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#36379B",
                      position: "relative",
                      "&::before": {
                        content: '""',
                        width: path === pathname ? "100%" : "0%",
                        height: "3px",
                        backgroundColor: "#1F0C5A",
                        display: "block",
                        transition: "width 0.5s",
                        position: "absolute",
                        left: 0,
                        bottom: -2,
                        borderRadius: "3px",
                      },
                      "&:hover::before": {
                        width: "100%",
                      },
                      fontWeight: 600,
                    }}
                  >
                    {name}
                  </Typography>
                </Link>
              );
            })}
            <Divider
              sx={{
                bgcolor: mainTheme.palette.primary.extraDark,
                width: "100%",
                height: "1px",
                borderRadius: "1px",
              }}
            />
            <Link to={PAGES.LOGIN.path} style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "36.5px",
                  background: mainTheme.palette.primaryGradient.main,
                }}
              >
                Login
              </Button>
            </Link>
          </Stack>
        </Drawer>
      </Stack>
    </ThemeProvider>
  );
}
